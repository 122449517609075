import { forwardRef, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={0} sx={{p:10}} ref={ref} variant="filled" {...props} />;
});

const withMessages = 
  (WrappedComponent) => {
    return (props) => {
      const refreshState = { message: "", callback: null, severity: "success", duration: 2000};
      const [open, setOpen] = useState(false);
      const [state, setState] = useState(refreshState);
      
      const handleClose = () => {
        setOpen(false);
        if (state.callback) {
          state.callback();
          setState(refreshState);
        }
      };
      
      return (
        <>
          <WrappedComponent {...props} 
            showMessage={(message, _callback = () => {}, severity='success', duration) => {
              setState({
                message: message,
                callback: _callback,
                severity: severity,
                duration: duration ?? 2000
              });
              setOpen(message !== "");
            }}
          />
          <Snackbar
            open={open}
            autoHideDuration={state.duration}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={() => setOpen(false)} severity={ state.severity } sx={{ width: '100%', color: 'white', padding: 2, borderRadius: 0}}>
              { state.message }
            </Alert>
          </Snackbar>
        </>
      );
    };
  };

export default withMessages;