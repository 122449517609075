import { useState, useEffect } from "react";
import withMessages from "../../../components/WithMessages";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { SelectSx, TextField } from "../../../theme/vs-theme";
import {
  Box,
  InputLabel,
  Button,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import dotenv from "react-dotenv";
import countries from "../../register/ConstCod";
import { useTranslation } from "react-i18next";

const PatientProfilePage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  const [errors, setErrors] = useState({});
  const { id } = useParams();
  const { t } = useTranslation();
  const [relativeCountryCode, setRelativeCountryCode] = useState(null);
  const [guardianCountryCode, setGuardianCountryCode] = useState(null);
  const [emergencyCountryCode, setEmergencyCountryCode] = useState(null);
  const [gpCountryCode, setGpCountryCode] = useState(null);
  const [institutionCountryCode, setInstitutionCountryCode] =
    useState(null);
  const [relativePhone, setRelativePhone] = useState(null);
  const [guardianPhone, setGuardianPhone] = useState(null);
  const [emergencyPhone, setEmergencyPhone] = useState(null);
  const [gpPhone, setGpPhone] = useState(null);
  const [institutionPhone, setInstitutionPhone] = useState(null);
  const [form, setForm] = useState({
    relative_fullname: null,
    relative_email: null,
    relative_phone: null,
    guardian_fullname: null,
    guardian_email: null,
    guardian_phone: null,
    emergency_fullname: null,
    emergency_phone: null,
    emergency_email: null,
    gp_fullname: null,
    gp_email: null,
    gp_phone: null,
    institution_fullname: null,
    institution_email: null,
    institution_phone: null,
  });

  const styles = {
    column: {
      margin: "0.5%",
      width: "49%",
      gap: "40px",
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      gap: "10px",
    },
    inputFields: {
      borderRadius: "12px",
      border: "1px solid #869AE8",
      padding: "0 20px",
      fontFamily: "Poppins",
      color: "#869AE8",
      fontSize: "14px",
      height: "46px",
      minWidth: "150px",
      width: "100%",
      marginTop: "5px",
    },
    backgroundBox: {
      width: "100%",
      padding: "40px 40px",
      backgroundColor: "#FFFFFF",
      borderRadius: "18px",
      margin: "10px 0px",
    },
    buttons: {
      marginTop: "40px",
      borderRadius: "15px",
      color: "white",
    },
    fields: {
      borderRadius: "12px",
      backgroundColor: "#FCFCFC",
      border: "1px solid #869AE8",
      padding: "10px 12px",
      color: "#869AE8",
    },
  };

  useEffect(() => {
    if (location.state?.patient) {
      props.setTitle(["Pacientes", location.state.patient], "Datos de Perfil");
    }
    getProfileData();
  }, []);

  const getProfileData = () => {
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/users/" + id + "/contacts",
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            setForm({
              relative_fullname: response.data.relative?.fullname,
              relative_email: response.data.relative?.email,
              relative_phone: response.data.relative?.phone,
              guardian_fullname: response.data.guardian?.fullname,
              guardian_email: response.data.guardian?.email,
              guardian_phone: response.data.guardian?.phone,
              emergency_fullname: response.data.emergency?.fullname,
              emergency_phone: response.data.emergency?.phone,
              emergency_email: response.data.emergency?.email,
              gp_fullname: response.data.gp?.fullname,
              gp_email: response.data.gp?.email,
              gp_phone: response.data.gp?.phone,
              institution_fullname: response.data.institution?.fullname,
              institution_email: response.data.institution?.email,
              institution_phone: response.data.institution?.phone,
            });
            let parts = null;
            if (response.data.relative?.phone) {
              parts = response.data.relative?.phone.split(" ");
              setRelativeCountryCode(parts[0]);
              setRelativePhone(parts.slice(1).join(" "));
            }
            if (response.data.emergency?.phone) {
              parts = response.data.emergency?.phone.split(" ");
              setEmergencyCountryCode(parts[0]);
              setEmergencyPhone(parts.slice(1).join(" "));
            }

            if (response.data.guardian?.phone) {
              parts = response.data.guardian?.phone.split(" ");
              setGuardianCountryCode(parts[0]);
              setGuardianPhone(parts.slice(1).join(" "));
            }
            if (response.data.gp?.phone) {
              parts = response.data.gp?.phone.split(" ");
              setGpCountryCode(parts[0]);
              setGpPhone(parts.slice(1).join(" "));
            }
            if (response.data.institution?.phone) {
              parts = response.data.institution?.phone.split(" ");
              setInstitutionCountryCode(parts[0]);
              setInstitutionPhone(parts.slice(1).join(" "));
            }
            if (response.data.institution?.email) {
              parts = response.data.institution?.email.split(" ");
              setInstitutionCountryCode(parts[0]);
              setInstitutionPhone(parts.slice(1).join(" "));
            }
          }
        });
    };
    fetchData();
  };

  const updateProfileData = () => {
    let updatedForm = { ...form };

    if (relativeCountryCode && relativePhone) {
      updatedForm.relative_phone = `${relativeCountryCode} ${relativePhone}`;
    }
    if (emergencyCountryCode && emergencyPhone) {
      updatedForm.emergency_phone = `${emergencyCountryCode} ${emergencyPhone}`;
    }
    if (gpCountryCode && gpPhone) {
      updatedForm.gp_phone = `${gpCountryCode} ${gpPhone}`;
    }
    if (institutionCountryCode && institutionPhone) {
      updatedForm.institution_phone = `${institutionCountryCode} ${institutionPhone}`;
    }
    if (institutionCountryCode && institutionPhone) {
      updatedForm.institution_phone = `${institutionCountryCode} ${institutionPhone}`;
    }
    if (guardianCountryCode && guardianPhone) {
      updatedForm.guardian_phone = `${guardianCountryCode} ${guardianPhone}`;
    }

    setForm(updatedForm);
    const url = new URL(
      "/institutions/v1/users/" + id + "/contacts",
      dotenv.API_URL
    );
    axios
      .post(url.href, updatedForm, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        props.showMessage(
          "Se modificaron los datos de perfil del paciente correctamente.",
          () => navigate(-1)
        );
      })
      .catch((error) => {
        setErrors(error.response.data?.errors);
      });
  };

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <div>
      <Box sx={styles.backgroundBox}>
        <Box display="flex" sx={{ gap: "40px" }}>
          <Box sx={styles.column}>
            <Box>
              <Box sx={styles.row}>
                <Box width={"100%"}>
                  <InputLabel htmlFor="relative_fullname">
                    {t("family_name_surname")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="relative_fullname"
                    value={form.relative_fullname}
                    name="relative_fullname"
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.relative_fullname && errors.relative_fullname[0]}
              </FormHelperText>
            </Box>
            <Box sx={styles.row}>
              <Box width={"100%"}>
                <InputLabel htmlFor="relative_email">
                  {t("family_email")}
                </InputLabel>
                <TextField
                  fullWidth
                  id="relative_email"
                  type="email"
                  value={form.relative_email}
                  name="relative_email"
                  onChange={handleChange}
                />
              </Box>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"25%"}>
                  <InputLabel>{t("country_code")}</InputLabel>
                  <Select
                    sx={[SelectSx, { width: "100%" }]}
                    id="cphone_country_code-input"
                    name="phone_country_code"
                    value={relativeCountryCode}
                    onChange={(evt) => setRelativeCountryCode(evt.target.value)}
                    renderValue={(selected) => {
                      let country = countries.find(
                        (country) => country.code === selected
                      );
                      return country ? country.code : "";
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        key={country.code + country.name}
                        value={country.code}
                      >
                        {country.name} {country.code}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box width={"70%"} ml={"5%"}>
                  <InputLabel htmlFor="relative_phone">
                    {t("family_phone")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="relative_phone"
                    value={relativePhone}
                    onChange={(evt) => setRelativePhone(evt.target.value)}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.relative_phone && errors.relative_phone[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"100%"}>
                  <InputLabel htmlFor="emergency_fullname">
                    {t("emergency_name")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="emergency_fullname"
                    value={form.emergency_fullname}
                    name="emergency_fullname"
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.emergency_fullname && errors.emergency_fullname[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box>
                <Box sx={{ ...styles.row, marginBottom: "45px" }}>
                  <Box width={"100%"}>
                    <InputLabel htmlFor="emergency_email">
                      {t("emergency_email")}
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="emergency_email"
                      value={form.emergency_email}
                      name="emergency_email"
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
                <FormHelperText
                  id="name-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.emergency_email && errors.emergency_email[0]}
                </FormHelperText>
              </Box>
              <Box sx={styles.row}>
                <Box width={"25%"}>
                  <InputLabel>{t("country_code")}</InputLabel>
                  <Select
                    sx={[SelectSx, { width: "100%" }]}
                    id="cphone_country_code-input"
                    name="phone_country_code"
                    value={emergencyCountryCode}
                    onChange={(evt) =>
                      setEmergencyCountryCode(evt.target.value)
                    }
                    renderValue={(selected) => {
                      let country = countries.find(
                        (country) => country.code === selected
                      );
                      return country ? country.code : "";
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        key={country.code + country.name}
                        value={country.code}
                      >
                        {country.name} {country.code}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box width={"70%"} ml={"5%"}>
                  <InputLabel htmlFor="emergency_phone">
                    {t("emergency_phone")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="emergency_phone"
                    value={emergencyPhone}
                    onChange={(evt) => setEmergencyPhone(evt.target.value)}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.emergency_phone && errors.emergency_phone[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"100%"}>
                  <InputLabel htmlFor="institution_fullname">
                    {t("intitution_name")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="institution_fullname"
                    value={form.institution_fullname}
                    name="institution_fullname"
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.institution_fullname && errors.institution_fullname[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"100%"}>
                  <InputLabel htmlFor="institution_email">
                    {t("email_intitution")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="institution_email"
                    value={form.institution_email}
                    name="institution_email"
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.institution_email && errors.institution_email[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"25%"}>
                  <InputLabel>{t("country_code")}</InputLabel>
                  <Select
                    sx={[SelectSx, { width: "100%" }]}
                    id="cphone_country_code-input"
                    name="phone_country_code"
                    value={institutionCountryCode}
                    onChange={(evt) =>
                      setInstitutionCountryCode(evt.target.value)
                    }
                    renderValue={(selected) => {
                      let country = countries.find(
                        (country) => country.code === selected
                      );
                      return country ? country.code : "";
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        key={country.code + country.name}
                        value={country.code}
                      >
                        {country.name} {country.code}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box width={"70%"} ml={"5%"}>
                  <InputLabel htmlFor="institution_phone">
                    {t("phone_intitution")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="institution_phone"
                    value={institutionPhone}
                    onChange={(evt) => setInstitutionPhone(evt.target.value)}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.institution_phone && errors.institution_phone[0]}
              </FormHelperText>
            </Box>
          </Box>
          <Box sx={styles.column}>
            <Box>
              <Box sx={styles.row}>
                <Box width={"100%"}>
                  <InputLabel htmlFor="guardian_fullname">
                    {t("guardian_name_lastname")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="guardian_fullname"
                    value={form.guardian_fullname}
                    name="guardian_fullname"
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.guardian_fullname && errors.guardian_fullname[0]}
              </FormHelperText>
            </Box>
            <Box sx={styles.row}>
              <Box width={"100%"}>
                <InputLabel htmlFor="guardian_email">
                  {t("guardian_email")}
                </InputLabel>
                <TextField
                  fullWidth
                  id="guardian_email"
                  type="email"
                  value={form.guardian_email}
                  name="guardian_email"
                  onChange={handleChange}
                />
              </Box>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"25%"}>
                  <InputLabel>{t("country_code")}</InputLabel>
                  <Select
                    sx={[SelectSx, { width: "100%" }]}
                    id="cphone_country_code-input"
                    name="phone_country_code"
                    value={guardianCountryCode}
                    onChange={(evt) => setGuardianCountryCode(evt.target.value)}
                    renderValue={(selected) => {
                      let country = countries.find(
                        (country) => country.code === selected
                      );
                      return country ? country.code : "";
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        key={country.code + country.name}
                        value={country.code}
                      >
                        {country.name} {country.code}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box width={"70%"} ml={"5%"}>
                  <InputLabel htmlFor="guardian_phone">
                    {t("guardian_phone")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="guardian_phone"
                    value={guardianPhone}
                    onChange={(evt) => setGuardianPhone(evt.target.value)}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.guardian_phone && errors.guardian_phone[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"100%"}>
                  <InputLabel htmlFor="gp_fullname">
                    {t("name_surname_practitioner")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="gp_fullname"
                    value={form.gp_fullname}
                    name="gp_fullname"
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.gp_fullname && errors.gp_fullname[0]}
              </FormHelperText>
            </Box>
            <Box sx={styles.row}>
              <Box width={"100%"}>
                <InputLabel htmlFor="gp_email">
                  {t("email_practitioner")}
                </InputLabel>
                <TextField
                  fullWidth
                  id="gp_email"
                  type="email"
                  value={form.gp_email}
                  name="gp_email"
                  onChange={handleChange}
                />
              </Box>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"25%"}>
                  <InputLabel>{t("country_code")}</InputLabel>
                  <Select
                    sx={[SelectSx, { width: "100%" }]}
                    id="cphone_country_code-input"
                    name="phone_country_code"
                    value={gpCountryCode}
                    onChange={(evt) => setGpCountryCode(evt.target.value)}
                    renderValue={(selected) => {
                      let country = countries.find(
                        (country) => country.code === selected
                      );
                      return country ? country.code : "";
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        key={country.code + country.name}
                        value={country.code}
                      >
                        {country.name} {country.code}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box width={"70%"} ml={"5%"}>
                  <InputLabel htmlFor="gp_phone">
                    {t("phone_practitioner")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="gp_phone"
                    value={gpPhone}
                    onChange={(evt) => setGpPhone(evt.target.value)}
                  />
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.gp_phone && errors.gp_phone[0]}
              </FormHelperText>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            onClick={() => navigate(-1)}
            sx={[styles.buttons, { background: "#D04444", width: "232px" }]}
          >
            {t("cancel_modification")}
          </Button>
          <Button
            onClick={() => {
              updateProfileData();
            }}
            sx={[styles.buttons, { background: "#5EC360", width: "285px" }]}
          >
            {t("modify_profile_data")}
          </Button>
        </Box>
      </Box>
    </div>
  );
};
export default withMessages(PatientProfilePage);
