import AlertsTable from "../../components/AlertsTable";

const AlertsIndexPage = (props) => {

  const endpoint = "/institutions/v1/alerts";

  return (
    <>
      <AlertsTable
        fromAlertsPage = {true}
        endpoint = {endpoint}
      ></AlertsTable>
    </>
  );
};

export default AlertsIndexPage;
