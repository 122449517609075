import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Grid from "@mui/material/Unstable_Grid2";
import { Paper, Backdrop, CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import WithMessages from "../../components/WithMessages";
import dotenv from "react-dotenv";
import axios from "axios";
import { useTranslation } from "react-i18next";

const EnrollmentsPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (user?.token) {
      setLoading(true);
      const url = new URL(
        "/institutions/v1/medical-groups/enrollments",
        dotenv.API_URL
      );
      url.searchParams.set("email", searchParams.get("email"));
      url.searchParams.set(
        "medical_group_id",
        searchParams.get("medical_group_id")
      );
      url.searchParams.set("signature", searchParams.get("signature"));
      axios
        .post(
          url.toString(),
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((response) => {
          props.showMessage(t("invitation_from_medical_team_accepted"), () =>
            navigate(`/`)
          );
          setLoading(false);
        })
        .catch((error) => {
          props.showMessage(
            t("invitation_from_medical_team_rejected") +
              error.response.data?.message,
            () => navigate("/"),
            "error"
          );
          setLoading(false);
        });
    }
  }, []);
  return (
    <div>
      <Grid xs={12} sx={{ mt: 4 }}>
        <Paper sx={{ backgroundColor: "white", padding: 4 }} elevation={0}>
          <Grid container direction="row" spacing={2}>
            <CircularProgress color="inherit" />
          </Grid>
        </Paper>
      </Grid>
      <Backdrop
        sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default WithMessages(EnrollmentsPage);
