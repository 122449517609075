import { Box, Typography, TextField, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import QuestionSurveyCard from "../../components/QuestionSurveyCard";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SpecificSurveyTemplatePage = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const [template, setTemplate] = useState();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.template) {
      props.setTitle([], location.state.template);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = new URL(`/institutions/v1/templates/${id}`, dotenv.API_URL);
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            setTemplate(response.data[0]);
          }
        })
        .catch()
        .finally();
    };
    fetchData();
  }, []);

  const styles = {
    survey: {
      margin: "29px",
      width: "100%",
      title: {
        color: "#285D84",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "33px",
        textAlign: "center",
      },
      description: {
        textAlign: "center",
        color: "#285D84",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.3px",
      },
      instructions: {
        color: "#285D84",
        marginTop: "22px",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "150%",
        letterSpacing: "-0.011em",
      },
    },
    questions: {
      display: "flex",
      flexDirection: "column",
      margin: "0 auto 20px",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "50px",
      question: {
        justifyContent: "center",
        width: "87%",
        margin: "20px 0px",
      },

      send_button: {
        marginTop: "12px",
        marginBottom: "70px",
        height: "46px",
        width: "87%",
        background:
          "linear-gradient(265.22deg, #3677A7 44.62%, #285D84 101.88%)",
        boxShadow: "2px 2px 6px rgba(54, 119, 167, 0.25)",
        borderRadius: "30px",
        border: "0",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "20px",
        color: "white",
      },
      titulo: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        letterSpacing: "0.005em",
        textAlign: "left",
        color: "#869AE8",
      },

      caja4: {
        width: "87%",
        height: "95%",
      },
      place: {
        color: "#869AE8",
      },
    },
  };

  return (
    <div>
      <Box sx={styles.questions}>
        <Box sx={styles.questions.question}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={10}>
              <Typography sx={styles.questions.titulo}>{t("title")}</Typography>
              <TextField
                value={template?.title}
                InputProps={{
                  style: {
                    borderRadius: "12px",
                    gap: "10px",
                    opacity: "0.5px",
                    border: "1px solid #869AE8",
                    color: "#869AE8",
                  },
                }}
                multiline
                variant="outlined"
                fullWidth
                disabled={true}
              ></TextField>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={styles.questions.titulo}>
                {t("patologyes")}
              </Typography>
              <TextField
                value={template?.pathologies.map((x) => x.name).join("/")}
                InputProps={{
                  style: {
                    padding: "0px, 16px, 0px, 16px",
                    borderRadius: "12px",
                    gap: "10px",
                    border: "1px solid #869AE8",
                    color: "#869AE8",
                  },
                }}
                disabled={true}
              ></TextField>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <Box sx={styles.questions.caja4}>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={4}>
              <Typography sx={styles.questions.titulo}>
                {t("description")}
              </Typography>
              <TextField
                value={template?.description}
                sx={styles.questions.place}
                InputProps={{
                  style: {
                    height: "345px",
                    borderRadius: "12px",
                    border: "1px solid #869AE8",
                    color: "#869AE8",
                  },
                }}
                multiline
                variant="outlined"
                fullWidth
                disabled={true}
              ></TextField>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={styles.questions.titulo}>
                {t("instructions")}
              </Typography>
              <TextField
                value={template?.instructions}
                InputProps={{
                  style: {
                    height: "345px",
                    borderRadius: "12px",
                    border: "1px solid #869AE8",
                    color: "#869AE8",
                  },
                }}
                multiline
                variant="outlined"
                fullWidth
                disabled={true}
              ></TextField>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={styles.questions.titulo}>
                {t("gratitude_message")}
              </Typography>
              <TextField
                value={template?.ty_message}
                InputProps={{
                  style: {
                    height: "345px",
                    borderRadius: "12px",
                    border: "1px solid #869AE8",
                    color: "#869AE8",
                  },
                }}
                multiline
                variant="outlined"
                fullWidth
                disabled={true}
              ></TextField>
            </Grid>
          </Grid>
        </Box>
        <Box sx={styles.questions.question}>
          {template?.questions.map((question) => (
            <QuestionSurveyCard
              question={question}
              key={question.id}
            ></QuestionSurveyCard>
          ))}
        </Box>
      </Box>
    </div>
  );
};
export default SpecificSurveyTemplatePage;
