import { useEffect, useState } from "react";
import i18n from 'i18next';
import axios from "axios";
import dotenv from "react-dotenv";
import { useParams } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import theme from "../../theme/vs-theme";
import { useLocation } from "react-router-dom";
import withMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";
import { Box, Button, CssBaseline, Divider, Grid, Typography } from "@mui/material";

const RegisterPage = (props) => {
  const { email } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const locale = searchParams.get('locale');
    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, [location.search, i18n]);

  const confirmUnsubscribe = () => {
    const url = new URL(
      `/institutions/v1/users/unsubscribe/${email}${location.search}`,
      dotenv.API_URL
    );
    axios
      .post(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUnsubscribed(true);
        } 
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setErrorMessage(t('already_unsubscribed'));
        } else {
          setErrorMessage(t('unsubscribe_failed'));
        }
      });
  };

  const boxStyle = {
    width: "100%",
    padding: "20px 30px",
    backgroundColor: "#FFFFFF",
    borderRadius: "18px",
    margin: "10px 0px",
  };
  
  const titleStyle = {
    fontFamily: "Poppins",
    color: "#737589",
    fontSize: "22px",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "20px",
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          borderRadius: "2px",
          background: "aliceblue",
          p: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container component="main" flexDirection="column" alignItems="center" spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="/logo.png" alt="logo" width={320} />
          </Grid>

          <Grid item xs={12} sx={{ width: "100%" }}>
            <Divider sx={{ borderBottomWidth: 5, borderColor: "#869AE8" }} />
          </Grid>

          {unsubscribed ? (
            <Grid item xs={12}>
              <Box sx={titleStyle}>{t("unsubscription_successful")}</Box>
              <Box sx={titleStyle}>{t("thank_you")}</Box>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Box sx={titleStyle}>{t("dear_user")},</Box>
              </Grid>
          
              <Grid item xs={12}>
                <Box sx={titleStyle}>{t("please_press_the_button_to_confirm_the_unsubscription_process")}</Box>
              </Grid>

              <Button 
                onClick={confirmUnsubscribe} 
                sx={{ 
                  backgroundColor: "#3657D9", 
                  color: "#FFFFFF", 
                  '&:hover': {
                    backgroundColor: "#284CA0"
                  }
                }}
              >
                {t("confirm")}
              </Button>
            </Grid>
          )}
          {errorMessage && (
            <Grid item xs={12}>
              <Typography sx={{ color: "red", fontSize: "16px", textAlign: "center" }}>
                {errorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{ width: "100%" }}>
            <Divider sx={{ borderBottomWidth: 5, borderColor: "#869AE8" }} />
          </Grid>
        </Grid>
      </Box>
    </MuiThemeProvider>
  );
};

export default withMessages(RegisterPage);
