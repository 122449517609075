import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  InputLabel,
  Paper
} from "@mui/material";

const CustomizeParameters = (props) => {
  const [paramUpdate, setParamUpdate] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleInputChange = (event, param) => {
    setParamUpdate({
      ...paramUpdate,
      [param.slug]: Number(event.target.value),
    });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#3657D9",
      fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const cancel = () => {
    setParamUpdate({});
    navigate(-1);
  };

  const styles = {
    columnTitle: {
      color: "#3657D9",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      letterSpacing: "0.32px",
    },
    input: {
      border: "none",
      background: "none",
      textAlign: "center",
      fontSize: "14px",
      fontFamily: "Poppins",
    },
    buttons: {
      marginTop: "20px",
      borderRadius: "15px",
      color: "white",
      margin: "20px",
    },
  };

  return (
    <Box>
      <TableContainer sx={styles.container} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={styles.columnTitle} align="center">
                {t("parameter")}
              </StyledTableCell>
              <StyledTableCell sx={styles.columnTitle} align="center">
                {t("comparison")}
              </StyledTableCell>
              <StyledTableCell sx={styles.columnTitle} align="center">
                {props.firstTitle}
              </StyledTableCell>
              <StyledTableCell sx={styles.columnTitle} align="center">
                {props.secondTitle}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(props.data.params).map(([key, value], index) => (
              <Fragment key={index}>
                {value[0].default ? (
                  <InputLabel sx={[styles.columnTitle, { padding: "15px" }]}>
                    {key}
                  </InputLabel>
                ) : (
                  <></>
                )}
                {value.map((param) =>
                  param.default ? (
                    <StyledTableRow key={param.name}>
                      <StyledTableCell>{param.name}</StyledTableCell>
                      <StyledTableCell align="center">
                        {param.comparison}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {param.default}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <input
                          type="number"
                          style={styles.input}
                          onChange={(event) => handleInputChange(event, param)}
                          value={
                            paramUpdate[param.slug]
                              ? paramUpdate[param.slug]
                              : param.patient_value
                              ? param.patient_value
                              : param.default
                          }
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    <></>
                  )
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>{" "}
      <Box sx={{ display: "flex" }}>
        <Button
          onClick={cancel}
          sx={[styles.buttons, { background: "#D04444" }]}
        >
          {t("cancel_modification")}
        </Button>
        <Button
          onClick={() => props.updateParams(paramUpdate)}
          sx={[styles.buttons, { background: "#5EC360" }]}
        >
          {t("update_parameter")}
        </Button>
      </Box>
    </Box>
  );
};
export default CustomizeParameters;
