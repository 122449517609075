import { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper
} from "@mui/material";
import { TextField } from "../theme/vs-theme";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";

const EnrollDoctorModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ email: "" });
  const [errors, setErrors] = useState({});
  const { user } = useAuth();
  const { t } = useTranslation();

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const enrollDoctor = () => {
    setLoading(true);
    let url = new URL(
      "/institutions/v1/medical-groups/" +
        props.medicalGroupId +
        "/request-doctor-enroll-link",
      dotenv.API_URL
    ).href;
    axios
      .post(url, form, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) =>
        props.showMessage(t("invitation_medical"), () => {
          setForm({ email: "" });
          props.onClose();
        })
      )
      .catch((error) => {
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        } else {
          props.showMessage(t("anerror_has_been"), () => {}, "error");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 0,
          p: 4,
          width: "50%",
          borderRadius: "16px",
          textAlign: "center",
        }}
      >
        <h2
          id="modal-modal-title"
          style={{ color: "#3988E3", fontWeight: 1200 }}
        >
          {t("invitation_medical_group")}
        </h2>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Paper sx={{ minWidth: "60%" }} elevation={0}>
            <Grid xs={12}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="email-input">
                  {t("please_input_email_doctor")}
                </InputLabel>
                <TextField
                  id="email-input"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="email-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors?.email && errors.email[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={12} sx={{ mt: 4 }}>
              <Button
                variant="contained"
                component="span"
                color="error"
                sx={{ width: 200, ml: 3 }}
                onClick={props.onClose}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                component="span"
                color="success"
                sx={{ width: 200, ml: 3 }}
                disabled={loading}
                onClick={enrollDoctor}
              >
                {t("send_invitation")}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EnrollDoctorModal;
