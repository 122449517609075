import { useEffect } from 'react';
import withMessages from '../../components/WithMessages';
import CustomizeParametersWithPathology from '../../components/CustomizeParametersWithPathology';
import { useLocation, useParams } from 'react-router-dom';

const MedicalGroupsCustomizeParams = (props) => {
    const { id } = useParams();
    const endpoint = `params/medicalgroup/${id}`
    const location = useLocation();

    useEffect(()=>{
        if (location.state?.medicalGroup){
        props.setTitle(["Equipos Médicos",location.state.medicalGroup]);
        }
    },[])

    return (
        ['superadmin', 'admin-institution', 'admin-customer'].some(
            r => props.role.includes(r)
          ) ?
            <CustomizeParametersWithPathology
                endpoint={endpoint}
                showMessage={props.showMessage}
                withPathology = {false}
            >
            </CustomizeParametersWithPathology>
            : <></>
    );
}

export default withMessages(MedicalGroupsCustomizeParams);
