import { Box } from "@mui/material";

const MembershipCard = (props) => {
    const styles = {
        card: {
            color: props.color,
            background: props.background,
            display: 'flex',
            padding: "24px",
            borderRadius: "26px"
        },
        title: {
            fontSize: "18px",
            fontWeight: 600
        },
        subtitle: {
            fontSize: "16px",
            fontWeight: 400,
            marginLeft: "10px"
        },
        value: {
            fontSize: "34px",
            fontWeight: 700,
            marginLeft: "10px"
        },
        circle: {
            width: "79px",
            height: "79px",
            borderRadius: "50%",
            background: "rgba(255, 255, 255, 0.26)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }
    }
    return(
        <Box sx={styles.card}>
            <Box sx={styles.circle}>
                <img style={{'maxHeight': '50px'}} src={props.icon}/>
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} ml={'10px'}>
                <Box sx={styles.title}>{props.title}</Box>
                <Box sx={styles.value}>{props.value}</Box>
            </Box>
        </Box>
    );
}
export default MembershipCard;