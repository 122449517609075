import { Paper, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";

const NoMatch = () => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Paper elevation={0} className="no-match" sx={{ p: 10 }}>
          <Typography variant="h1" component="h1" gutterBottom>
            404
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            {t("not_found_page")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("dont_exist_page")}
          </Typography>
          <br />
          <Button
            sx={{ width: 300 }}
            variant="contained"
            color="primary"
            href="/"
          >
            {t("go_major_page")}
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NoMatch;
