import { useEffect, useState, useMemo } from "react";
import MaterialTable from "../../components/MaterialTable";
import { Button, Modal, Box, Typography, Avatar } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { useTranslation } from "react-i18next";

const AdminCustomerIndexPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleClose = () => setOpenDeleteModal(false);
  const [deleteId, setDeleteId] = useState(null);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const location = useLocation();

  useEffect(() => {
    if (location.state?.customer) {
      props.setTitle([t("agrup"), location.state.customer], "");
    }
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = useMemo(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: t("name_lastname"),
        size: 240,
        Cell: (cell) => (
          <Grid spacing={1} container>
            <Grid xs={3}>
              <Avatar
                alt="avatar"
                src={cell.row.original?.avatar?.url}
                sx={{
                  width: "50px",
                  height: "50px",
                  border: 4,
                  backgroundColor: "#D9D9D9",
                  borderColor: "white",
                }}
              />
            </Grid>
            <Grid xs={9} sx={{ mt: 2 }}>
              {cell.row.original.name} {cell.row.original.lastname}
            </Grid>
          </Grid>
        ),
      },
      {
        id: "email",
        accessorKey: "email",
        header: t("email"),
      },
      {
        id: "phone",
        accessorKey: "phones.0.number",
        header: t("phone"),
        enableSorting: false,
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 250,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) => (
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`./${cell.row.original.id}/edit`, {
                  state: {
                    customer: location.state.customer,
                    admin:
                      cell.row.original.name + " " + cell.row.original.lastname,
                  },
                })
              }
              src={"/edit.png"}
              alt={"administrativos"}
            />
            &nbsp;&nbsp;&nbsp;
            <img
              style={{ cursor: "pointer" }}
              onClick={() => confirmDelete(cell.row.original.id)}
              src={"/delete.png"}
              alt={"administrativos"}
            />
            &nbsp;&nbsp;&nbsp;
          </>
        ),
      },
    ],
    [navigate, t]
  );

  function confirmDelete(id) {
    setOpenDeleteModal(true);
    setDeleteId(id);
  }

  function refreshPage() {
    window.location.reload(false);
  }

  function deleteAdmin() {
    setIsLoading(true);
    axios
      .delete(
        new URL(`/institutions/v1/admins/customers/${deleteId}`, dotenv.API_URL)
          .href,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(() =>
        props.showMessage(
          t("admin_group_delete_successfully"),
          () => refreshPage
        )
      )
      .catch((error) => props.showMessage(error.response.data?.errors))
      .finally(() => {
        setIsLoading(false);
        setOpenDeleteModal(false);
        refreshPage();
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsRefetching(true);
      setIsError(false);

      const url = new URL("/institutions/v1/admins/customers", dotenv.API_URL);
      url.searchParams.set("page", `${pagination.pageIndex + 1}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("globalFilter", globalFilter ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("customer", `${id}`);

      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setData(response.data.data);
          setRowCount(response.data.total);
          setIsError(false);
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
        });
    };
    fetchData();
  }, [columnFilters, globalFilter, pagination, sorting, user]);

  return (
    <>
      <div>
        <MaterialTable
          columns={columns}
          data={data}
          title="Admin"
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: t("error_fetching_data"),
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
        <br />
        <Button
          variant="contained"
          onClick={() =>
            navigate("./add", {
              state: {
                customer: location.state.customer,
              },
            })
          }
          color="success"
        >
          {t("enrol")}
        </Button>
      </div>
      <Modal open={openDeleteModal} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("delete_admin")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t("are_you_sure")}
          </Typography>
          <Button onClick={deleteAdmin}>{t("yes")}</Button>
          <Button onClick={() => setOpenDeleteModal(false)}>{t("no")}</Button>
        </Box>
      </Modal>
    </>
  );
};

export default AdminCustomerIndexPage;
