import { useState, useEffect }from "react";
import {
  Button,
  Select,
  Grid,
  Box,
  Typography,
  MenuItem,
  IconButton,
} from "@mui/material";
import { TextField } from "../../theme/vs-theme";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import SpecificAlertData from "../../components/SpecificAlertData";
import AlertCommentsTable from "../../components/AlertCommentsTable";
import ProfileData from "../../components/ProfileData";
import MedicalCoverageData from "../../components/MedicalCoverageData";
import HealthData from "../../components/HealthData";
import PatientValuesTable from "../../components/PatientValuesTable";
import { useTranslation } from "react-i18next";
import { Modal } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";

const SpecificAlertPage = (props) => {
  const { user } = useAuth();
  const { id } = useParams();
  const [alert, setAlert] = useState([]);
  const [comments, setComments] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [form, setForm] = useState({
    status: 0,
    comment: "",
  });
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setForm({
      status: alert.status,
    });
  }, [alert]);

  useEffect(() => {
    if (location.state?.patient) {
      props.setTitle(["Alarmas"], location.state.patient);
    }
  }, []);
  const buttonsICC = [
    {
      title: t("heart_rate"),
      slug: "heart-rate",
    },
    {
      title: t("weight"),
      slug: "weight",
    },
    {
      title: t("blood_pressure"),
      slug: "systolic-blood-pressure,diastolic-blood-pressure",
    },
    {
      title: t("oxygen_saturation"),
      slug: "oxygen-saturation",
    },
    {
      title: t("how_i_feel"),
      slug: "how-i-feel",
    },
    {
      title: t("ecg"),
      slug: "ecg,ecg-graph",
    },
  ];

  const buttonsHTA = [
    {
      title: t("blood_pressure"),
      slug: "systolic-blood-pressure,diastolic-blood-pressure,morning-systolic-pressure-1,morning-diastolic-pressure-1,morning-systolic-pressure-2,morning-diastolic-pressure-2,afternoon-systolic-pressure-1,afternoon-diastolic-pressure-1,afternoon-systolic-pressure-2,afternoon-diastolic-pressure-2",
    },
    {
      title: t("heart_rate"),
      slug: "heart-rate,morning-heart-rate-1,morning-heart-rate-2,afternoon-heart-rate-1,afternoon-heart-rate-2",
    },
  ];

  const styles = {
    box: {
      backgroundColor: "#FCFCFC",
      borderRadius: "18px",
      boxShadow:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
      padding: "36px 20px",
    },
    title: {
      fontSize: "30px",
      color: "#3988E3",
      fontWeight: "700",
      lineHeight: "42px",
      letterSpacing: "0.75px",
    },
    select: {
      margin: "20px 0px",
      borderRadius: "12px",
      border: "1px solid #869AE8",
      color: "#869AE8",
    },
    buttons: {
      borderRadius: "15px",
      padding: "10px 24px",
      marginTop: "20px",
      color: "white",
    },
    alertResume: {
      margin: "20px 0",
      padding: "20px",
      borderRadius: "16px",
      background: "#E7E9FF",
      wordBreak: "break-word",
      title: {
        color: "#1E1E1E",
        fontSize: "16px",
        fontWeight: "600",
        letterSpacing: "0.048px",
        marginBottom: "12px",
      },
    },
    modal: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "761px",
      height: "265px",
      padding: "40px 31px 40px 31px",
      gap: "40px",
      borderRadius: "16px 16px 16px 16px",
      backgroundColor: "white",
      opacity: 1,
      boxShadow: 24,
      p: 4,
      color: "background: #FCFCFC",
    },
    titleModal: {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: "600",
      textAlign: "center",
      color: "#575565",
    },
    button: {
      width: "144px",
      height: "47px",
      color: "white",
      backgroundColor: "#B33A3A",
    },
    button2: {
      width: "144px",
      height: "47px",
      color: "white",
      backgroundColor: "#5EC360",
    },
    closeButton: {
      position: "absolute",
      top: 8,
      right: 8,
    },
  };

  useEffect(() => {
    getAlert();
    getComments();
  }, []);

  const getAlert = () => {
    const fetchData = async () => {
      const url = new URL("/institutions/v1/alerts/" + id, dotenv.API_URL);
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setAlert(response.data);
          getPatientData(response.data.patient_id);
        })
        .finally(() => {});
    };
    fetchData();
  };

  const getPatientData = (patientId) => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/patients/${patientId}/resume`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            setPatientData(response.data);
          }
        })
        .catch()
        .finally();
    };
    fetchData();
  };

  const getComments = () => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/alerts/${id}/get-comments`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setComments(response.data);
        })
        .finally(() => {});
    };
    fetchData();
  };

  const handleChange = (event) => {
    if (event.target.name == "comment" && form.status == 0) {
      setForm({ ...form, comment: event.target.value, status: 2 });
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  const updateAlert = () => {
    const fetchData = async () => {
      const url = new URL("/institutions/v1/alerts/" + id, dotenv.API_URL);
      axios
        .put(
          url.href,
          {
            id: id,
            status: form.status,
            comment: form.comment,
            doctor_id: user.user.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then(() => {
          setForm({ ...form, comment: "" });
          getAlert();
          getComments();
        });
    };
    fetchData();
  };

  const handleDeleteComment = (commentId) => {
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/alerts/comment/" + commentId,
        dotenv.API_URL
      );
      axios
        .delete(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          getComments();
        });
    };
    fetchData();
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmLeave = () => {
    setShowModal(false);
    updateAlert();
  };
  return (
    <>
      <div>
        <SpecificAlertData
          alert={alert.length == 0 ? null : alert}
        ></SpecificAlertData>
        <br />
      </div>
      <Box sx={styles.box}>
        <Typography sx={styles.title}>{t("update_alarm")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={7} padding={"20px 20px"}>
            <Select
              sx={[styles.select, { width: "205px" }]}
              name={"status"}
              onChange={handleChange}
              defaultValue={0}
              value={form.status}
            >
              <MenuItem disabled value={0}>
                <em>{t("state")}</em>
              </MenuItem>
              <MenuItem value={1}>{t("pending")}</MenuItem>
              <MenuItem value={2}>{t("in_process")}</MenuItem>
              <MenuItem value={3}>{t("finished")}</MenuItem>
            </Select>
            <TextField
              sx={{ width: "100%" }}
              minRows={10}
              multiline
              name={"comment"}
              placeholder={"Comentario"}
              onChange={handleChange}
              value={form.comment}
            ></TextField>
          </Grid>
          <Modal
            open={showModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styles.modal}>
              <IconButton sx={styles.closeButton} onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>

              <Typography sx={styles.titleModal}>
                {t("question_areyou")}
              </Typography>
              <Grid container spacing={2} justifyContent="center" mt={3}>
                <Grid item>
                  <Button onClick={handleCloseModal} sx={styles.button}>
                    {t("no")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleConfirmLeave}
                    color="primary"
                    sx={styles.button2}
                  >
                    {t("yes")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Grid item xs={5}>
            <AlertCommentsTable
              deleteComment={handleDeleteComment}
              comments={comments}
            ></AlertCommentsTable>
            {alert.resume ? (
              <Box
                sx={{
                  ...styles.alertResume,
                  maxWidth: "100%",
                  overflow: "auto",
                }}
              >
                <Typography sx={styles.alertResume.title}>
                  {t("alarm_summary")}
                </Typography>
                <Typography
                  style={{
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    maxHeight: "100px",
                    overflowY: "auto",
                  }}
                >
                  {alert.resume}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            <div align="right">
              <Button
                sx={[
                  styles.buttons,
                  { width: "187px", backgroundColor: "#5EC360" },
                ]}
                disabled={form.status == 0}
                onClick={() => {
                  if (!form.comment && form.status === 3) {
                    setShowModal(true);
                  } else {
                    updateAlert();
                  }
                }}
              >
                {t("save")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
      {Object.keys(patientData).length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={7} padding={"20px 20px"}>
            <div>
              <Typography
                sx={[styles.title, { color: "#3657D9", margin: "20px 0" }]}
              >
                {t("general")}
              </Typography>
              <ProfileData
                id={alert?.patient_id}
                profileData={patientData?.profile_data}
                summary={patientData?.collaborative_summary}
              ></ProfileData>
              <MedicalCoverageData
                medicalCoverageData={patientData?.medical_coverage_data}
              ></MedicalCoverageData>
              <HealthData
                healthData={patientData?.health_data}
                disnea={patientData?.disnea}
              ></HealthData>
            </div>
          </Grid>
          <Grid item xs={5} padding={"20px 20px"} width={"100%"}>
            <Typography
              sx={[styles.title, { color: "#3657D9", margin: "20px 0" }]}
            >
              {t("my_values")}
            </Typography>
            <PatientValuesTable
              buttons={
                alert.patient_pathology == "ICC" ? buttonsICC : buttonsHTA
              }
              userId={alert.patient_id}
            ></PatientValuesTable>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default SpecificAlertPage;
