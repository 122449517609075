import { useState, useEffect }from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Chip,
  TableFooter,
  TablePagination,
  IconButton,
  TableCell,
  tableCellClasses,
  styled
} from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';
import dotenv from 'react-dotenv';
import { useTranslation } from "react-i18next";

const PatientValuesTable = (props) => {
  const { user } = useAuth();
  const [selectedButton, setSelectedButton] = useState(props.buttons[0].slug);
  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [unit, setUnit] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    getValues(props.buttons[0].slug);
  }, []);

  const handleChange = (event) => {
    let measurementSlug = event.target.value;
    setSelectedButton(measurementSlug);
    getValues(measurementSlug);
    determineUnit(measurementSlug);
  }

  const determineUnit = (measurementSlug) => {
    switch (measurementSlug) {
      case "heart-rate": 
      case "heart-rate,morning-heart-rate-1,morning-heart-rate-2,afternoon-heart-rate-1,afternoon-heart-rate-2":
        setUnit("lpm");
        break;
      case "weight":
        setUnit("kg");
        break;
      case "systolic-blood-pressure,diastolic-blood-pressure":
      case "systolic-blood-pressure,diastolic-blood-pressure,morning-systolic-pressure-1,morning-diastolic-pressure-1,morning-systolic-pressure-2,morning-diastolic-pressure-2,afternoon-systolic-pressure-1,afternoon-diastolic-pressure-1,afternoon-systolic-pressure-2,afternoon-diastolic-pressure-2":
        setUnit("mmHg");
        break;
      case "oxygen-saturation":
        setUnit("%");
        break;
      default:
        setUnit("");
        break;
    }
  }

  const determineDevice = (deviceId) => {
    switch (deviceId) {
      case 1: 
        return t("manual");
      case 2:
        return t("scale");
      case 3:
        return t("blood_pressure_monitor");
      case 4:
        return t("pulse_oximeter");
      case 5:
        return t("ecg_monitor");
    }
  }

  const getValues = (parameter) => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = new URL(`/institutions/v1/users/${props.userId}/history/${parameter}/`, dotenv.API_URL);
      axios.get(url.href, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then((response) => {
          setValues(response.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchData();
  }

  const styles = {
    container: {
      borderRadius: "18px",
      display: "flex",
      flexDirection: "column",
      background: "#FCFCFC",
    },
    buttons: {
      background: "#F6F6F6",
    },
    chip: {
      backgroundColor: "rgba(207, 134, 232, 0.20)",
      color: "#7046B8",
      fontSize: "14px",
      fontWeight: "600"
    },
    cells: {
      width: "50%",
      textAlign: "center",
      color: "#737589",
      fontWeight: "500"
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#3657D9",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const ATRIAL_FIBRILLATION = "1";
  const SINUS_RHYTHM = "2";
  const DOUBTFUL_TRACING = "3";

  const determineLabel = (value) => {
    switch (value) {
      case ATRIAL_FIBRILLATION:
        return t("fibrilacion_auricular");
      case SINUS_RHYTHM:
        return t("sinus_rhythm");
      case DOUBTFUL_TRACING:
        return t("doubtful_layout");
      default:
        return t("no_data");
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <ToggleButtonGroup
          sx={{ overflowX: "scroll", borderRadius: "16px" }}
          color="primary"
          value={selectedButton}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          {
            props.buttons.map(button => (
              <ToggleButton
                sx={{ width: "-webkit-fill-available" }}
                key={button.slug}
                value={button.slug}>
                {button.title}
              </ToggleButton>
            ))
          }
        </ToggleButtonGroup>
        {
          isLoading ?
            <center>
              <h2>{t("loading")}</h2>
            </center>
            :
            <TableContainer>
              <Table>
                <TableBody>
                  {
                    values.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map(value => (
                      <StyledTableRow key={value.measurement_id}>
                        <StyledTableCell sx={styles.cells}>
                          {
                            selectedButton == "how-i-feel" ?
                              <img src={`/face${value.value}.png`} /> :
                              selectedButton == "ecg,ecg-graph" ?
                                <Chip sx={styles.chip} label={determineLabel(value.value.slice(0, 1))} /> :
                                <Chip sx={styles.chip} label={value.value + " " + unit} />
                          }
                        </StyledTableCell>
                        <StyledTableCell sx={styles.cells}>
                          {value.created_at}
                        </StyledTableCell>
                        {
                          selectedButton == "ecg,ecg-graph" ?
                            <StyledTableCell sx={styles.cells}>
                              <a href={value.value.slice(2)} target="_blank">
                                <IconButton>
                                  <img src={"/pdf.png"} />
                                </IconButton>
                              </a>
                            </StyledTableCell>
                            : unit == "lpm" ?
                            <StyledTableCell sx={styles.cells}>
                              {determineDevice(value.device_id)}
                            </StyledTableCell>
                            : <></>
                        }
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPage={rowsPerPage}
                      count={values.length}
                      page={currentPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      labelRowsPerPage="Filas por página:"
                    >
                    </TablePagination>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
        }
      </Box>
    </>
  )

}
export default PatientValuesTable;