import { useAuth } from "../../../hooks/useAuth";
import { InputLabel } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import dotenv from "react-dotenv";
import WithMessages from "../../../components/WithMessages";
import CustomizeSurveys from "../../../components/CustomizeSurvey";
import { useTranslation } from "react-i18next";

const CustomizeSpecificSurveyPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const institution = location.state.institution;
  const medicalGroup = location.state.medicalGroup;
  const form = location.state.form;

  useEffect(() => {
    if (location.state?.template) {
      props.setTitle([
        [t("scoreboard"), " ", t("parameterize"), " ", location.state.template],
      ]);
    }
  }, []);

  const saveCustomization = (receivedForm) => {
    if (receivedForm.sendOption == 1) {
      receivedForm.timeAfterEnrollmentNumber = null;
      receivedForm.timeAfterEnrollmentType = null;
    }
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/surveys/configuration",
        dotenv.API_URL
      );
      axios
        .post(
          url.href,
          {
            institution: receivedForm.institution,
            medicalGroup: receivedForm.medicalGroup,
            sendFrequencyType: receivedForm.frequencyType,
            sendFrequency: receivedForm.frequencyNumber,
            responsePeriodType: receivedForm.answerPeriodType,
            responsePeriod: receivedForm.answerPeriodNumber,
            sendOption: receivedForm.sendOption,
            timeAfterEnrollmentType: receivedForm.timeAfterEnrollmentType,
            timeAfterEnrollment: receivedForm.timeAfterEnrollmentNumber,
            templates: [id],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((response) => {
          props.showMessage(t("success_parameterize_surveys"));
          navigate(-1);
        })
        .catch((e) => {
          props.showMessage(
            t("an_error_has_occurred_try_later"),
            () => {},
            "error"
          );
        });
    };
    fetchData();
  };

  return (
    <div>
      {institution && medicalGroup ? (
        <CustomizeSurveys
          institutions={[institution]}
          medicalGroups={[medicalGroup]}
          form={form}
          saveCustomization={saveCustomization}
        />
      ) : (
        <InputLabel
          sx={{
            textAlign: "center",
            color: "#3657D9",
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: "600",
          }}
        >
          {t("loading")}
        </InputLabel>
      )}
    </div>
  );
};
export default WithMessages(CustomizeSpecificSurveyPage);
