import { useState, useRef, useEffect } from "react";
import axios from "axios";
import dotenv from 'react-dotenv';
import { useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import ICCReport from "./ICCReport";
import HTAReport from "./HTAReport";
import html2canvas from 'html2canvas';
import IosShareIcon from "@mui/icons-material/IosShare";
import jsPDF from 'jspdf';
import { useTranslation } from "react-i18next";

const GeneralReportPage = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [pages, setPages] = useState([]);
  const [creatingPDF, setCreatingPDF] = useState(false);
  const contentRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const url = new URL(
      `/institutions/v1/reports/${id}`,
      dotenv.API_URL
    );
    axios.get(url.href)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => { })
      .finally(() => { })
  }, []);

  const styles = {
    container: {
      margin: "40px 40px"
    },
    button: {
      marginTop: "20px",
      marginRight: "60px",
      fontSize: "12px",
      fontFamily: "Poppins",
      width: "200px",
      borderRadius: "15px",
      background: !creatingPDF ? "var(--primary-default, #3657D9)" : "#f4f5f8",
      color: !creatingPDF ? "white" : "black",
    },
  };
  const generatePDF = async () => {
    setCreatingPDF(true);
    // Obtener el ancho del contenido
    const contentWidth = contentRef.current.getBoundingClientRect().width;
    // Obtener el contenido del componente como una imagen utilizando html2canvas
    const canvas = await html2canvas(contentRef.current, {
      width: contentWidth, // Ajustar el ancho del lienzo al ancho del contenido
    });

    const imgData = canvas.toDataURL('image/png');
    let width = window.innerWidth;
    let height = window.innerWidth * 1873 / 1299;
    // Definir el tamaño de página del documento PDF
    const pageSize = { width: width, height: height }; 
    // Calcular la cantidad de páginas en base al alto del lienzo
    const totalPages = Math.ceil(canvas.height / (pageSize.height ));

    // Crear un nuevo documento PDF
    const doc = new jsPDF({
      orientation: 'portrait', // o 'landscape' según sea necesario
      unit: 'pt', // Utilizar puntos como unidad de medida
      format: [pageSize.width , pageSize.height ], // Establecer el formato del documento
    });

    // Agregar todas las páginas al documento PDF
    let yOffset = 0;
    for (let i = 0; i < totalPages; i++) {
      if (i > 0) {
        doc.addPage([pageSize.width , pageSize.height ]); // Agregar nueva página al documento PDF
      }
      const pageCanvas = document.createElement('canvas');
      pageCanvas.width = pageSize.width;
      pageCanvas.height = pageSize.height ;
      const pageCtx = pageCanvas.getContext('2d');
      pageCtx.drawImage(canvas, 0, yOffset, pageSize.width, pageSize.height , 0, 0, pageSize.width, pageSize.height );
      const pageImgData = pageCanvas.toDataURL('image/png');
      doc.addImage(pageImgData, 'PNG', 0, 0);
      yOffset += pageSize.height;
    }

    // Guardar el documento como un archivo PDF
    doc.save(`reporte ${data?.data?.profile_data?.name} ${data?.data?.profile_data?.lastname}.pdf`);
    setCreatingPDF(false);
};

  return (
    <Box>
      {pages.length > 0 ?
        <div align="right">
          <Button
            sx={styles.button}
            onClick={generatePDF}
          >
            {!creatingPDF ? t("export") : t("generating_pdf")}
            {!creatingPDF ? <IosShareIcon sx={{ marginLeft: "5px", fontSize: "14px" }} /> : <></>}
          </Button>
        </div> : <></>}
      <Box ref={contentRef}>
        <Box sx={styles.container} >
          {
            data?.pathology == "ICC" ?
              <ICCReport printPages={(pages) => setPages(pages)} data={data} /> :
              <HTAReport printPages={(pages) => setPages(pages)} data={data} />
          }
        </Box>
      </Box>
    </Box>
  );
}
export default GeneralReportPage;