import { createContext, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from "./useAuth";
import dotenv from "react-dotenv";
import { useLocation } from "react-router-dom";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const { user } = useAuth() || {}; 
  const [language, setLanguage] = useState(i18n.language);
  const excludedPaths = ['/unsubscribe'];
  const isExcludedPath = excludedPaths.some(path => location.pathname.startsWith(path));

  useEffect(() => {
    const initializeLanguage = async () => {
      if (isExcludedPath) return;
      if (user) {
        const fetchedLanguage = await fetchLanguage();
        if (fetchedLanguage) {
          i18n.changeLanguage(fetchedLanguage);
          setLanguage(fetchedLanguage);
        }
      }
    };
    initializeLanguage();
  }, [i18n, user]);

  const changeLanguage = async (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    await saveLanguage(lng);
  };

  const fetchLanguage = async () => {
    try {
      if(user.user.id){
        const url = new URL("/institutions/v1/users/"+user.user.id+"/preferences/language", dotenv.API_URL);
        const response = await axios.get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        });
        return response.data.length == 0 ? 'es' : response.data.language;
      }
      
    } catch (error) {
      console.error("Error fetching language:", error);
      return null;
    }
  };

  const saveLanguage = async (language) => {
    try {
      const url = new URL("/institutions/v1/users/set-user-preference", dotenv.API_URL);
      await axios.post(
        url.href,
        { 'key': 'language', 'value':language },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      );
    } catch (error) {
      console.error("Error saving language:", error);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
