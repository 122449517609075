import { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@mui/material";
import SendSurveysButtons from "../../../components/SendSurveysButtons";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import dotenv from "react-dotenv";
import WithMessages from "../../../components/WithMessages";
import axios from "axios";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const AssignSurveyPage = (props) => {
  const [selectedMode, setSelectedMode] = useState("institution");
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const title = location.state.title;

  useEffect(() => {
    if (location.state?.title) {
      props.setTitle([], location.state.title);
    }
  }, []);

  const sendSurveys = (
    selectedButtons,
    sendObjects,
    endpoint,
    medicalGroupId = null
  ) => {
    let key =
      selectedMode == "institution"
        ? "institutions"
        : selectedMode == "medicalGroup"
        ? "medical_groups"
        : "users";
    let objectsToSend = selectedButtons
      .map((value, index) => (value ? sendObjects[index].id : null))
      .filter((value) => value !== null);
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/templates/${id}/${endpoint}`,
        dotenv.API_URL
      );
      if (medicalGroupId) {
        url.searchParams.append("medical_group_id", medicalGroupId);
      }
      axios
        .post(
          url.href,
          { [key]: objectsToSend },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((response) => {
          if (response) {
            props.showMessage(
              t("survey_has_been_assigned_successfully"),
              () => {
                navigate(-1);
              }
            );
          }
        })
        .catch(() => {
          props.showMessage(
            t("an_error_has_occurred_try_later"),
            () => {},
            "error"
          );
        })
        .finally();
    };
    fetchData();
  };

  const styles = {
    mainBox: {
      padding: "40px 30px",
      borderRadius: "18px",
      border: "1px",
      background: "#FFFFFF",
    },
    levelsend: {
      marginTop: "30px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    titleEac: {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      letterSpacing: "0.01em",
      color: "#3657D9",
    },
    levelButtons: {
      width: "190px",
      height: "40px",
      borderRadius: "10px",
      background: "#3988E3",
      fontSize: "14px",
      color: "white",
    },
  };

  return (
    <div style={styles.mainBox}>
      <Grid container>
        <Grid item xs={12} sx={styles.title}>
          <Typography sx={styles.titleEac}>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={styles.levelsend}>
            <Typography>{t("shipping_level")}</Typography>
            <Button
              sx={[
                styles.levelButtons,
                selectedMode == "institution"
                  ? { opacity: 1 }
                  : { opacity: 0.4 },
              ]}
              onClick={() => setSelectedMode("institution")}
            >
              {t("institution")}
            </Button>
            <Button
              sx={[
                styles.levelButtons,
                selectedMode == "medicalGroup"
                  ? { opacity: 1 }
                  : { opacity: 0.4 },
              ]}
              onClick={() => setSelectedMode("medicalGroup")}
            >
              {t("medicalGroup")}
            </Button>
            <Button
              sx={[
                styles.levelButtons,
                selectedMode == "patient" ? { opacity: 1 } : { opacity: 0.4 },
              ]}
              onClick={() => setSelectedMode("patient")}
            >
              {t("patient")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <SendSurveysButtons mode={selectedMode} sendSurveys={sendSurveys} />
    </div>
  );
};
export default WithMessages(AssignSurveyPage);
