import { useState, useEffect, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import MaterialTable from "../../../components/MaterialTable";
import moment from "moment";
import { useTranslation } from "react-i18next";
const ECGHistory = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const ATRIAL_FIBRILLATION = "1";
  const SINUS_RHYTHM = "2";
  const DOUBTFUL_TRACING = "3";
  
  const determineLabel = (values) => {
    //15 is ECG type
    const targetValue = values.find(item => item.type.id === 15);
    if (targetValue) {
      switch (targetValue.value) {
        case ATRIAL_FIBRILLATION:
          return t("fibrilacion_auricular");
        case SINUS_RHYTHM:
          return t("sinus_rhythm");
        case DOUBTFUL_TRACING:
          return t("doubtful_layout");
        default:
          return t("no_data"); 
      }
    }
    return t("no_data"); 
  };


  const columns = useMemo(() => [
    {
      id: "updated_at",
      header: t("date_time"),
      Cell: (cell) => (
        <Box sx={styles.date}>
          <p>{moment(cell.row.original.updated_at).format("DD/M/yy HH:mm")}</p>
        </Box>
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      id: "type",
      header: t("type"),
      Cell: (cell) => (
        <Box sx={styles.type}>
            {determineLabel(cell.row.original?.values || [])}
        </Box>
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      id: "action",
      header: t("actions"),
      Cell: (cell) =>
        cell.row.original.ecg_graph_url ? (
          <a href={cell.row.original.ecg_graph_url} target="_blank">
            <IconButton>
              <img src={"/pdf.png"} />
            </IconButton>
          </a>
        ) : (
          <></>
        ),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ], [t]);

  useEffect(() => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    const url = new URL(
      `/institutions/v1/patients/${props.patient}/ecg/`,
      dotenv.API_URL
    );
    url.searchParams.set("page", `${pagination.pageIndex + 1}`);
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        setData(response.data?.data);
        setRowCount(response.data?.total);
      })
      .catch(() => setIsError(true))
      .finally(() => {
        setIsLoading(false);
        setIsRefetching(false);
      });
  }, [pagination]);

  const styles = {
    width: "100%",
    padding: "20px 30px",
    backgroundColor: "#FFFFFF",
    borderRadius: "18px",
    margin: "10px 0px",
    title: {
      fontFamily: "Poppins",
      color: "#737589",
      fontSize: "22px",
      fontWeight: 600,
      textAlign: "center",
      marginBottom: "20px",
    },
    date: {
      fontFamily: "Poppins",
      color: "#667085",
      fontSize: "14px",
      textAlign: "center",
    },
    type: {
      fontFamily: "Poppins",
      color: "#667085",
      fontSize: "12px",
      fontWeight: 600,
      textAlign: "center",
    },
  };
  return (
    <>
      <Box sx={styles}>
        <Box sx={styles.title}>{t("historical_electrocardiograms")}</Box>
        <MaterialTable
          columns={columns}
          data={data}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: t("error_fetching_data"),
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
      </Box>
    </>
  );
};
export default ECGHistory;
