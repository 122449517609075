import { useState, useEffect } from "react";
import {
  Button,
  Select,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import dotenv from "react-dotenv";
import { TextField, SelectSx } from "../../theme/vs-theme";
import WithMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";

const PatientsCreatePage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [medicalGroups, setMedicalGroups] = useState([]);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    cellphone: "",
    medical_group_id: "",
    email: "",
    doctor_email: user.user.email,
    doctor_id: user.user.id,
  });

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const url = new URL("/institutions/v1/medical-groups", dotenv.API_URL);
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setMedicalGroups(response.data.data);
          if (response.data.data.length === 0) {
            setErrors({
              ...errors,
              ["medical_group_id"]: [t("must_register_medical_team")],
            });
          }
        })
        .catch()
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  const sendEnrollLink = () => {
    const url = new URL(
      "/patients/v1/users/email-exists/" + form.email,
      dotenv.API_URL
    );
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        if (!res.data.exists) {
          axios
            .post(
              new URL(
                "/institutions/v1/patients/request-enroll-link",
                dotenv.API_URL
              ).href,
              form,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Accept: "application/json",
                  Authorization: "Bearer " + user.token,
                },
              }
            )
            .then(() =>
              props.showMessage(t("send_message_email"), () =>
                navigate(`/patients`)
              )
            )
            .catch((error) => {
              setErrors(error.response.data?.errors);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setErrors({
            ...errors,
            ["email"]: [t("email_in_use")],
          });
        }
      });
  };

  return (
    <div>
      <Grid xs={12} sx={{ mt: 4 }}>
        <Paper sx={{ backgroundColor: "white", padding: 4 }} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel shrink htmlFor="medical-group-input">
                  {t("medicalGroup")}
                </InputLabel>
                <Select
                  displayEmpty
                  sx={SelectSx}
                  id="medical-group-input"
                  name="medical_group_id"
                  value={form.medical_group_id}
                  onChange={handleChange}
                  defaultValue={1}
                >
                  {medicalGroups.map((medicalGroup) => (
                    <MenuItem value={medicalGroup.id} key={medicalGroup.id}>
                      {medicalGroup.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  id="medical-group-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors?.medical_group_id && errors.medical_group_id[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel shrink htmlFor="cellphone-input">
                  {t("cel_phone_patient")}
                </InputLabel>
                <TextField
                  id="cellphone-input"
                  name="cellphone"
                  value={form.cellphone}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="cellphone-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors?.cellphone && errors.cellphone[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <InputLabel shrink htmlFor="email-input">
                  {t("email_patient")}
                </InputLabel>
                <TextField
                  id="email-input"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="email-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors?.email && errors.email[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={() => sendEnrollLink()}
                color="success"
              >
                {t("enrol_patient")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Backdrop
        sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default WithMessages(PatientsCreatePage);
