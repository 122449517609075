import withMessages from '../../components/WithMessages';
import CustomizeParametersWithPathology from '../../components/CustomizeParametersWithPathology';

const CustomizeDefaultParametersPage = (props) => {
  return (
    ['superadmin', 'admin-vs'].some(
      r => props.role.includes(r)
    ) ?
      <CustomizeParametersWithPathology
        endpoint = "params/pathology"
        withPathology = {true}
        showMessage={props.showMessage}
      >        
      </CustomizeParametersWithPathology>
    :<></>
  );
}

export default withMessages(CustomizeDefaultParametersPage);
