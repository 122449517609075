import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
const LabelRadioGroup = (props) => {
  const align = props.align ?? "start";
  const space = props.space ?? "baseline";
  const styles = {
    labels: {
      whiteSpace: "pre-wrap",
      color: "#869AE8",
      fontSize: "14px",
      fontWeight: "500",
    },
    options: {
      whiteSpace: "pre-wrap",
      color: "#3657D9",
      fontSize: "18px",
      fontWeight: "500",
    },
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
        alignSelf: align,
        placeContent: space,
        justifyContent: "end",
        width: "400px",
        ...props.sx,
      }}
    >
      <InputLabel sx={styles.labels}>{props.label}</InputLabel>
      <RadioGroup
        sx={{ display: "flex", flexDirection: "row" }}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      >
        <FormControlLabel
          sx={styles.options}
          value={"1"}
          control={<Radio />}
          label="Si"
        />
        <FormControlLabel
          sx={styles.options}
          value={"2"}
          control={<Radio />}
          label="No"
        />
      </RadioGroup>
    </Box>
  );
};
export default LabelRadioGroup;
