import { FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const UseModalityMedicalInformation = (props) => {
  const { t } = useTranslation();
  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "15px",
      marginTop: "20px",
    },
    title: {
      color: "#3988E3",
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "600",
      paddingBottom: "10px",
    },
    labels: {
      whiteSpace: "pre-wrap",
      color: "#3657D9",
      fontSize: "18px",
      fontWeight: "500",
    },
  };

  return (
    <Box sx={styles.container}>
      <InputLabel sx={styles.title}>{t("use_modality")}</InputLabel>
      <Box sx={{ display: "flex" }}>
        <RadioGroup
          defaultValue={props.modality}
          name="modality"
          onChange={(evt) => props.onChange("modality", evt.target.value)}
        >
          <FormControlLabel
            sx={styles.labels}
            value={"1"}
            control={<Radio />}
            label={t("monitor_blood_pressure")}
          />
          <FormControlLabel
            sx={styles.labels}
            value={"2"}
            control={<Radio />}
            label={t("personal_control_blood_pressure")}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
};
export default UseModalityMedicalInformation;
