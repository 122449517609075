import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import TextFIeld from "../../components/InputTextField";
import ReactInputVerificationCode from "react-input-verification-code";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";

const ForgotPasswordPage = () => {
  const [form, setForm] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const from = location.state?.from || "/health-dashboard";
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [code, setCode] = useState("");
  const [forgotStep, setForgotStep] = useState(1);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const headers = {
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };

  const theme = createTheme();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleForgot = () => {
    axios
      .post(
        dotenv.API_URL + "/auth/forgot-password",
        { email: form.email },
        headers
      )
      .then(() => {
        setForgotStep(2);
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.email
        ) {
          setError({
            message: error.response.data.errors.email[0],
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setError({
            message: error.response.data.error,
          });
        } else {
          setError({
            message: t("server_not_respond"),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendVerificationCode = () => {
    axios
      .post(
        dotenv.API_URL + "/auth/check-token",
        { email: form.email, token: code },
        headers
      )
      .then(() => {
        setForgotStep(3);
      })
      .catch((error) => {
        setError({
          message: error?.response?.data?.error ?? t("server_not_respond"),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePassword = () => {
    setLoading(true);
    setError([]);
    axios
      .post(
        dotenv.API_URL + "/auth/reset-password",
        {
          email: form.email,
          password: form.password,
          password_confirmation: confirmPassword,
          token: code,
        },
        headers
      )
      .then((response) => {
        setAlertMessage(t("password_was_modified"));
        setOpenAlert(true);
        setTimeout(() => {
          login(response.data, from);
        }, 2000);
      })
      .catch((error) => {
        setError({
          message: error?.response?.data?.message ?? t("server_not_respond"),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCodeChange = (res) => {
    setCode(res);
  };

  const customProps = {
    autoFocus: true,
    length: 6,
    type: "number",
    onChange: handleCodeChange,
  };

  const styles = {
    title: {
      color: "#3657D9",
      fontFamily: "Poppins",
      fontSize: "22px",
      fontWeight: "700",
      lineHeight: "26px",
    },
    text: {
      color: "#575565",
      fontSize: "16px",
      whiteSpace: "pre-line",
    },
    code: {
      "--ReactInputVerificationCode-itemWidth": "56px",
      "--ReactInputVerificationCode-itemHeight": "56px",
      ".ReactInputVerificationCode__item": {
        borderRadius: "15px",
        fontFamily: "Poppins",
        color: "#869AE8",
      },
    },
    elerta: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    container: {
      minWidth: "200px",
      maxWidth: "386px",
      my: 20,
      mx: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      mt: 3,
      mb: 2,
      py: "12px",
      px: "26px",
      bgcolor: "#3657D9",
      borderRadius: 4,
      textTransform: "capitalize",
      fontSize: "17px",
      fontFamily: "Poppins",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Button
            sx={[styles.button, { color: "white", ml: "20px" }]}
            startIcon={
              <img
                style={{ width: "10px", marginRight: "10px" }}
                src="back-white.png"
              />
            }
            onClick={() => navigate("/login")}
          >
            {t("goback")}
          </Button>
          <Box sx={styles.container}>
            <img src="logo.png" alt="logo" width={320} />
            <Box sx={{ mt: forgotStep < 2 ? "123px" : "69px" }}>
              {forgotStep == 1 ? (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <Box sx={styles.title}>{t("recover_passeword")}</Box>
                  <Box sx={styles.text}>
                    {t("input_email_asoc_send_verification")}
                  </Box>
                  <TextFIeld
                    required
                    fullWidth
                    id="email"
                    label={t("email")}
                    name="email"
                    autoComplete="on"
                    autoFocus
                    helperText={error?.errors?.email?.reduce(
                      (acc, cur) => acc + " " + cur,
                      ""
                    )}
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                  {error?.message ? (
                    <Alert severity="error" sx={{ mt: 3 }}>
                      {error.message}
                    </Alert>
                  ) : (
                    ""
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={styles.button}
                    onClick={handleForgot}
                    endIcon={<img style={{ width: "14px" }} src="arrow.png" />}
                  >
                    {t("send")}
                  </Button>
                </Box>
              ) : forgotStep == 2 ? (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <img
                    style={{ width: "136px", margin: "0 auto" }}
                    src="/mail-send.png"
                  />
                  <Box sx={[styles.title, { textAlign: "center" }]}>
                    {t("checkout_email")}
                  </Box>
                  <Box sx={styles.text}>
                    {t("send_intructions_create_newacount")}
                  </Box>
                  <Box sx={styles.text}>{t("input_unique_code")}</Box>
                  <Box sx={styles.code}>
                    <ReactInputVerificationCode {...customProps} />
                  </Box>
                  {error?.message ? (
                    <Alert severity="error" sx={{ mt: 3 }}>
                      {error.message}
                    </Alert>
                  ) : (
                    ""
                  )}

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={styles.button}
                    onClick={sendVerificationCode}
                    disabled={code.length < 6}
                    endIcon={<img style={{ width: "14px" }} src="arrow.png" />}
                  >
                    {t("check_code")}
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={styles.button}
                    onClick={handleForgot}
                    endIcon={<img style={{ width: "14px" }} src="arrow.png" />}
                  >
                    {t("resend_code")}
                  </Button>
                </Box>
              ) : (
                <>
                  <Box sx={[styles.title, { mb: "20px" }]}>
                    {t("recover_passeword")}
                  </Box>
                  <Box sx={[styles.elerta]}>{t("password_mustbe_diferet")}</Box>
                  <TextFIeld
                    required
                    fullWidth
                    id="new-password"
                    label={t("new_password")}
                    name="new-password"
                    type={showPassword ? "text" : "password"}
                    autoFocus
                    value={form.password}
                    onChange={(e) =>
                      setForm(
                        { ...form, password: e.target.value },
                        setPassword(e.target.value)
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextFIeld
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Confirmar nueva contraseña"
                    type={showPassword2 ? "text" : "password"}
                    id="password"
                    autoComplete="on"
                    color={error?.password ? "error" : "primary"}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setPassword2(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePasswordVisibility2}
                            edge="end"
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {error?.message ? (
                    <Alert severity="error" sx={{ mt: 3 }}>
                      {error.message}
                    </Alert>
                  ) : (
                    ""
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={styles.button}
                    onClick={changePassword}
                  >
                    {t("change_password")}
                  </Button>
                  {openAlert && (
                    <Alert
                      severity="success"
                      variant="filled"
                      onClose={() => setOpenAlert(false)}
                      open={openAlert}
                    >
                      {alertMessage}
                    </Alert>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(valores.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider>
  );
};
export default ForgotPasswordPage;
