import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AlertCommentsTable = (props) => {
  const { t } = useTranslation();
  const columns = [
    t("name_lastname"),
    t("date_time"),
    t("comments"),
    t("actions"),
  ];

  const deleteComment = props.deleteComment;

  const styles = {
    table: {
      title: {
        color: "#1E1E1E",
        fontSize: "16px",
        fontWeight: "600",
        letterSpacing: "0.048px",
        margin: "12px 0",
      },
      headers: {
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "20px",
        color: "#737589",
        textAlign: "center",
        padding: "15px 5px",
      },
      cells: {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#667085",
        textAlign: "center",
      },
    },
  };

  return (
    <>
      <Typography sx={styles.table.title}>{t("comment_history")}</Typography>
      <TableContainer
        component={Paper}
        sx={{ overflowY: "auto", height: "185px" }}
      >
        <Table>
          <TableHead sx={{ position: "sticky", top: "0px", margin: "0 0" }}>
            <TableRow sx={{ background: "#F9FAFB" }}>
              {columns.map((col) => (
                <TableCell key={col} sx={styles.table.headers}>
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.comments.map((comment) => (
              <TableRow key={comment.id}>
                <TableCell sx={styles.table.cells}>
                  {comment.doctor_name} {comment.doctor_lastname}
                </TableCell>
                <TableCell sx={styles.table.cells}>
                  {moment(comment.created_at).format("DD/M/yy HH:mm")}
                </TableCell>
                <TableCell sx={styles.table.cells}>{comment.content}</TableCell>
                <TableCell
                  sx={[styles.table.cells, { cursor: "pointer" }]}
                  onClick={() => deleteComment(comment.id)}
                >
                  <img src="/delete.png" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default AlertCommentsTable;
