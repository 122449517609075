import { InputLabel, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Linkify from 'linkify-react';

const ChatMessage = (props) => {

  const messageStyle = {
    container: {
      width: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    message: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.003em",
      whiteSpace: "pre-wrap !important",
      overflowWrap: "break-word"
    },
    datetime: {
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
    },
    anotherText: {
      color: "#575565",
      textAlign: "left"
    },
    loggedUserText: {
      color: "#FCFCFC",
      textAlign: "right"
    },
    anotherBox: {
      borderRadius: "13.248px 13.248px 13.248px 0px",
      alignSelf: "flex-start",
      background: "#DADADA",
    },
    loggedUserBox: {
      borderRadius: "13.248px 13.248px 0px 13.248px",
      background: '#3988E3',
      alignSelf: "flex-end",
    },
    messageBox: {
      width: "fit-content",
      margin: "5px",
      padding: "15px",
      maxWidth: "400px",
    }
  };

  return (
    <Box sx={messageStyle.container}>
      <Box sx={[messageStyle.messageBox, props.fromId === props.userId ? messageStyle.loggedUserBox : messageStyle.anotherBox]}>
        {
          props.attachmentType === 'image' ?
            <img style={{ maxWidth: "350px", maxHeight: "350px" }} src={props.attachmentFile} /> :
            props.attachmentFile.toString().substr(-3) === 'pdf' ?
              <Link to={props.attachmentFile.href} download={props.attachmentTitle} target="_blank" rel="noreferrer">
                <img style={{ maxWidth: "150px", maxHeight: "150px" }} src={'/pdfimage.png'} />
                <p>{props.attachmentTitle}</p>
              </Link> :
              props.attachmentFile.toString().substr(-3) === 'mp4' || props.attachmentFile.toString().substr(-3) === 'avi' || props.attachmentFile.toString().substr(-4) === 'webm' ?
                <>
                  <video controls>
                    <source src={props.attachmentFile} />
                  </video>
                </> :
                <></>
        }

        <InputLabel sx={[messageStyle.message, props.fromId === props.userId ? messageStyle.loggedUserText : messageStyle.anotherText]}>
          <Linkify options={{ target: '_blank' }} >{props.message}</Linkify>
        </InputLabel>
        <InputLabel sx={[messageStyle.datetime, props.fromId === props.userId ? messageStyle.loggedUserText : messageStyle.anotherText]}>{props.datetime}</InputLabel>
        <InputLabel sx={[messageStyle.datetime, props.fromId === props.userId ? messageStyle.loggedUserText : messageStyle.anotherText]}>{props.fromName}</InputLabel>
      </Box>
    </Box>
  );
}
export default ChatMessage;
