import { useState, useEffect } from "react";
import { Box, InputLabel } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import SurveyPatientResult from "../../../components/SurveyPatientResult";
import { useTranslation } from "react-i18next";

const PatientSurveysPage = (props) => {
  const { user } = useAuth();
  const { id } = useParams();
  const { t } = useTranslation();
  const [surveysGrouped, setSurveysGrouped] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.patient) {
      props.setTitle(["Pacientes", location.state.patient], "");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/surveys/user/${id}`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            let surveys = response.data[0].surveys;
            setLoading(false);
            if (surveys.length > 0) {
              let surveysToGroup = surveys.reduce(function (r, a) {
                r[a.template.title] = r[a.template.title] || [];
                r[a.template.title].push(a);
                return r;
              }, Object.create(null));
              setSurveysGrouped(surveysToGroup);
            }
          }
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    };
    fetchData();
  }, []);

  const styles = {
    surveys: {
      background: "white",
      borderRadius: "30px",
      minWidth: window.innerWidth - 380 + "px",
      padding: "28px 0px",
      title: {
        fontWeight: "700",
        fontSize: "30px",
        lineHeight: "41px",
        marginLeft: "37px",
      },
      grouped: {
        marginLeft: "38px",
        marginTop: "26px",
        title: {
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "27px",
          color: "#285D84",
          textTransform: "uppercase",
        },
        survey: {
          marginTop: "10px",
        },
      },
    },
    center: {
      textAlign: "center",
      marginTop: "50px",
    },
  };

  return (
    <div>
      <Box sx={styles.surveys}>
        {Object.entries(surveysGrouped).map((group) => (
          <>
            <Box sx={styles.surveys.grouped}>
              <InputLabel sx={styles.surveys.grouped.title}>
                {group[0]}
              </InputLabel>
              {group[1].map((survey) => (
                <Box sx={styles.surveys.grouped.survey}>
                  <SurveyPatientResult
                    key={survey.id}
                    patient={location.state.patient}
                    survey={survey}
                  ></SurveyPatientResult>
                </Box>
              ))}
            </Box>
          </>
        ))}
        {surveysGrouped.length == 0 && !loading ? (
          <InputLabel sx={[styles.surveys.grouped.title, styles.center]}>
            {t("this_patient_havent_surveys")}
          </InputLabel>
        ) : loading ? (
          <InputLabel sx={[styles.surveys.grouped.title, styles.center]}>
            {t("loading")}
          </InputLabel>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
};
export default PatientSurveysPage;
