import { Fragment, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  styled
} from "@mui/material"
import moment from "moment";
import { useTranslation } from "react-i18next";

const HealthData = (props) => {
  const { t } = useTranslation();

  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "15px",
      marginTop: "20px",
    },
    title: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
      paddingBottom: "10px",
    },
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#3657D9",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [params, setParams] = useState({});
  const [paramsDate, setParamsDate] = useState({});

  useEffect(() => {
    const params = {};
    const paramsDate = {};
    if (props.healthData) {
      Object.entries(props.healthData).forEach(([key, value]) => {
        if (key.includes("_date")) {
          paramsDate[key] = value;
        } else {
          params[key] = value;
        }
      });
    }
    setParams(params);
    setParamsDate(paramsDate);
  }, [props]);
  return (
    <Box>
      <TableContainer sx={styles.container} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={styles.title}>
                {t("health_data")}
              </StyledTableCell>
              <StyledTableCell align="center">{t("value")}</StyledTableCell>
              <StyledTableCell align="center">
                {t("data_loading")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(params).map(([key, value], index) => (
              <Fragment key={index}>
                <StyledTableRow>
                  <StyledTableCell>{key}</StyledTableCell>
                  <StyledTableCell align="center">
                    {key == "Clase funcional"
                      ? value?.value
                        ? props?.disnea[Number(value.value) - 1]
                        : "-"
                      : key == "Insuficiencia cardíaca" ||
                        key == "Insuficiencia renal significativa" ||
                        key == "Diabetes"
                      ? value?.value == 1
                        ? "SI"
                        : "NO"
                      : value?.value ?? "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {paramsDate[key + "_date"]?.value
                      ? moment(
                          paramsDate[key + "_date"]?.value,
                          "DD-MM-yyyy"
                        ).format("DD/MM/yyyy")
                      : "-"}
                  </StyledTableCell>
                </StyledTableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default HealthData;
