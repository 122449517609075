import { useState, useEffect }from "react";
import { InputLabel, Box } from "@mui/material";
import LabelRadioGroup from "./LabelRadioGroup";
import { TextField } from "../theme/vs-theme";
import moment from "moment";
import { useTranslation } from "react-i18next";

const HealthDataMedicalInformation = (props) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    cardiovascular_history: props.healthData?.cardiovascular_history,
    cardiovascular_history_date: moment(
      props.healthData?.cardiovascular_history_date,
      "DD-MM-YYYY"
    ).isValid()
      ? moment(
          props.healthData?.cardiovascular_history_date,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD")
      : null,
    heart_failure: props.healthData?.heart_failure,
    heart_failure_date: props.healthData?.heart_failure_date,
    significant_renal_insufficiency:
      props.healthData?.significant_renal_insufficiency,
    significant_renal_insufficiency_date:
      props.healthData?.significant_renal_insufficiency_date,
    significant_renal_insufficiency_creatinine:
      props.healthData?.significant_renal_insufficiency_creatinine,
    creatinine: props.healthData?.creatinine,
    creatinine_date: props.healthData?.creatinine_date,
    diabetes: props.healthData?.diabetes,
    diabetes_date: props.healthData?.diabetes_date,
    weight: props.healthData?.weight,
    weight_date: props.healthData?.weight_date,
    hematocrit: props.healthData?.hematocrit,
    hematocrit_date: props.healthData?.hematocrit_date,
    urea: props.healthData?.urea,
    urea_date: props.healthData?.urea_date,
    hba1c: props.healthData?.hba1c,
    hba1c_date: props.healthData?.hba1c_date,
    microalbuminuria: props.healthData?.microalbuminuria,
    microalbuminuria_date: props.healthData?.microalbuminuria_date,
    echocardiogram: props.healthData?.echocardiogram,
    fevi: props.healthData?.fevi,
    fevi_date: props.healthData?.fevi_date,
    valve_disease: props.healthData?.valve_disease,
    valve_disease_date: props.healthData?.valve_disease_date,
    eye_fundus: props.healthData?.eye_fundus,
    eye_fundus_date: props.healthData?.eye_fundus_date,
    polysomnography: props.healthData?.polysomnography,
    polysomnography_date: props.healthData?.polysomnography_date,
  });

  const handleChange = (evt) => {
    let { name, value } = evt.target;
    if (name.includes("_date")) {
      value = moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
    }
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    props.onChange("healthData", form);
  }, [form]);

  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "15px",
      marginTop: "20px",
    },
    title: {
      color: "#3988E3",
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "600",
      paddingBottom: "10px",
    },
    labels: {
      whiteSpace: "pre-wrap",
      color: "#869AE8",
      fontSize: "14px",
      fontWeight: "500",
    },
    inputFields: {
      borderRadius: "12px",
      border: "1px solid #869AE8",
      padding: "0 20px",
      fontFamily: "Poppins",
      color: "#869AE8",
      fontSize: "14px",
      height: "46px",
      minWidth: "150px",
      width: "100%",
      marginTop: "5px",
    },
    fields: {
      borderRadius: "12px",
      border: "1px solid #869AE8",
      padding: "10px",
      color: "#869AE8",
      minHeight: "65px",
      maxWidth: "390px",
      width: "100%",
      marginTop: "5px",
    },
    boxContainer: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
    inputEco: {
      borderRadius: "12px",
      border: "1px solid #869AE8",
      padding: "0 20px",
      fontFamily: "Poppins",
      color: "#869AE8",
      fontSize: "14px",
      height: "45px",
      minWidth: "150px",
      width: "100%",
      marginTop: "5px",
    },
    labels2: {
      whiteSpace: "pre-wrap",
      color: "#869AE8",
      fontSize: "14px",
      fontWeight: "500px",
    },
  };

  return (
    <Box sx={styles.container}>
      <InputLabel sx={styles.title}>{t("other_health_data")}</InputLabel>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={styles.boxContainer}>
          <Box sx={{ alignSelf: "self-end", width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="cardiovascular_history">
              {t("cardiovascular_history")}
            </InputLabel>
            <textarea
              style={styles.fields}
              value={form.cardiovascular_history}
              id="cardiovascular_history"
              rows={3}
              multiline
              name="cardiovascular_history"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end", marginBottom: "7px" }}>
            <InputLabel
              sx={styles.labels}
              htmlFor="cardiovascular_history_date"
            >
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(
                form.cardiovascular_history_date,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD")}
              id="cardiovascular_history_date"
              name="cardiovascular_history_date"
              onChange={handleChange}
              style={styles.inputFields}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box width="390px">
            <LabelRadioGroup
              space="space-between"
              value={form.heart_failure}
              name="heart_failure"
              align="end"
              label={t("heart_failure")}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="heart_failure_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              id="heart_failure_date"
              value={moment(form.heart_failure_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              name="heart_failure_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box width="390px">
            <LabelRadioGroup
              space="space-between"
              value={form.significant_renal_insufficiency}
              align="end"
              name="significant_renal_insufficiency"
              label={t("renal_insufficiency")}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel
              sx={styles.labels}
              htmlFor="significant_renal_insufficiency_date"
            >
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(
                form.significant_renal_insufficiency_date,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD")}
              id="significant_renal_insufficiency_date"
              name="significant_renal_insufficiency_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
          {form.significant_renal_insufficiency ? (
            <Box sx={{ alignSelf: "self-end" }}>
              <InputLabel
                sx={styles.labels}
                htmlFor="significant_renal_insufficiency_creatinine"
              >
                {t("creatinine")}
              </InputLabel>
              <input
                fullWidth
                value={form.significant_renal_insufficiency_creatinine}
                id="significant_renal_insufficiency_creatinine"
                type="number"
                name="significant_renal_insufficiency_creatinine"
                style={styles.inputFields}
                onChange={handleChange}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box sx={styles.boxContainer}>
          <Box width="390px">
            <LabelRadioGroup
              value={form.diabetes}
              space="space-between"
              align="end"
              name="diabetes"
              label={t("diabetes")}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="diabetes_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.diabetes_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              id="diabetes_date"
              name="diabetes_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ alignSelf: "self-end", width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="weight">
              {t("weight")} (kg)
            </InputLabel>
            <TextField
              value={form.weight}
              fullWidth
              id="weight"
              type="number"
              name="weight"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="weight_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.weight_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              id="weight_date"
              name="weight_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ alignSelf: "self-end", width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="hematocrit">
              {t("hematocrit")} (%)
            </InputLabel>
            <TextField
              value={form.hematocrit}
              fullWidth
              id="hematocrit"
              type="number"
              name="hematocrit"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="hematocrit_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.hematocrit_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              id="hematocrit_date"
              name="hematocrit_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ alignSelf: "self-end", width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="creatinine">
              {t("creatinine")}
            </InputLabel>
            <TextField
              value={form.creatinine}
              fullWidth
              id="creatinine"
              type="number"
              name="creatinine"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="creatinine_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.creatinine_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              id="creatinine_date"
              name="creatinine_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ alignSelf: "self-end", width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="urea">
              {t("urea")}
            </InputLabel>
            <TextField
              value={form.urea}
              fullWidth
              id="urea"
              type="number"
              name="urea"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="urea_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.urea_date, "DD-MM-YYYY").format("YYYY-MM-DD")}
              id="urea_date"
              name="urea_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ alignSelf: "self-end", width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="hba1c">
              {t("hbA1c")}
            </InputLabel>
            <TextField
              value={form.hba1c}
              fullWidth
              id="hba1c"
              type="number"
              name="hba1c"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="hba1c_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.hba1c_date, "DD-MM-YYYY").format("YYYY-MM-DD")}
              id="hba1c_date"
              name="hba1c_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ alignSelf: "self-end", width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="microalbuminuria">
              {t("microalbuminuria")}
            </InputLabel>
            <TextField
              value={form.microalbuminuria}
              fullWidth
              id="microalbuminuria"
              type="number"
              name="microalbuminuria"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="microalbuminuria_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.microalbuminuria_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              id="microalbuminuria_date"
              name="microalbuminuria_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ width: "580px" }}>
            <InputLabel sx={styles.labels2} htmlFor="echocardiogram">
              {t("echocardiogram")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.echocardiogram, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              id="echocardiogram"
              name="echocardiogram"
              style={styles.inputEco}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ alignSelf: "self-end", width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="fevi">
              FEVI (%)
            </InputLabel>
            <TextField
              value={form.fevi}
              fullWidth
              id="fevi"
              type="number"
              name="fevi"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="fevi_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.fevi_date, "DD-MM-YYYY").format("YYYY-MM-DD")}
              id="fevi_date"
              name="fevi_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="valve_disease">
              {t("valvulopathies")}
            </InputLabel>
            <textarea
              style={styles.fields}
              value={form.valve_disease}
              id="valve_disease"
              rows={3}
              multiline
              name="valve_disease"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="valve_disease_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.valve_disease_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              id="valve_disease_date"
              name="valve_disease_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="eye_fundus">
              {t("eye_fundus")}
            </InputLabel>
            <textarea
              style={styles.fields}
              value={form.eye_fundus}
              id="eye_fundus"
              rows={3}
              multiline
              name="eye_fundus"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="eye_fundus_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.eye_fundus_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              id="eye_fundus_date"
              name="eye_fundus_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ width: "390px" }}>
            <InputLabel sx={styles.labels} htmlFor="polysomnography">
              {t("polysomnography")}
            </InputLabel>
            <textarea
              style={styles.fields}
              value={form.polysomnography}
              id="polysomnography"
              rows={3}
              multiline
              name="polysomnography"
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="polysomnography_date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.polysomnography_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              id="polysomnography_date"
              name="polysomnography_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default HealthDataMedicalInformation;
