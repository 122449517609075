import { Button, Box, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const SurveyPatientResult = (props) => {
  const navigate = useNavigate();
  const date = useState(
    moment(props.survey.reception_date).format("DD/MM/YYYY hh:mm")
  );
  const [status, setStatus] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (props.survey.status == 1) setStatus("Sin responder");
    if (props.survey.status == 2) setStatus("Inactiva");
    if (props.survey.status == 3) setStatus("Pospuesta");
    if (props.survey.status == 4) setStatus("Completada");
    if (props.survey.status == 5) setStatus("Encuesta vencida");
  }, []);

  const calculateScore = () => {
    let score = 0;
    let total = 0;
    for (let i = 0; i < props.survey.template.questions.length; i++) {
      if (props.survey.answers.length > 0) {
        score += props.survey.answers[i].answer_data.value;
      }
      total += Math.max(
        ...props.survey.template.questions[i].data.map((q) => q.value)
      );
    }
    return score + "/" + total;
  };

  const styles = {
    survey: {
      display: "flex",
      width: "95%",
      background: "#D9E8F6",
      borderRadius: "20px",
      minHeight: "66px",
      date: {
        alignItems: "center",
        margin: "9px",
        padding: "0px 20px",
        display: "flex",
        background: "#B4D2EA",
        borderRadius: "12px",
      },
      text: {
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "27px",
        color: "#1C78BB",
        marginLeft: "10px",
      },
      status: {
        alignItems: "center",
        display: "flex",
        margin: "9px",
        padding: "14px",
        fontWeight: "600",
        fontSize: "14px",
        borderRadius: "12px",
      },
      button: {
        background: "#1C78BB",
        boxShadow: "2px 2px 6px 6px rgba(104, 144, 181, 0.25)",
        borderRadius: "30px",
        width: "165px",
        height: "40px",
        color: "white",
        border: "0px",
      },
    },
    right: {
      margin: "auto auto",
      marginRight: "20px",
    },
    red: {
      color: "#bb1c1c",
      backgroundColor: "#eab4b4",
    },
    blue: {
      color: "#1C78BB",
      background: "#B4D2EA",
    },
    green: {
      color: "#1bba3e",
      background: "#b9e8b2",
    },
  };

  return (
    <>
      <Box sx={styles.survey}>
        <Box sx={styles.survey.date}>
          <img src="/calendar_small.png" />{" "}
          <InputLabel sx={styles.survey.text}>{date}</InputLabel>
        </Box>
        <Box sx={[styles.survey.status, styles.blue]}>
          <img src="/trophy.png" />{" "}
          <InputLabel sx={styles.survey.text} marginBottom={0}>
            {calculateScore()}
          </InputLabel>
        </Box>
        {props.survey.status == 4 ? (
          <Button
            onClick={() =>
              navigate(`./${props.survey.id}`, {
                state: {
                  patient: props.patient,
                },
              })
            }
            sx={styles.right}
          >
            {t("see_results")}
            <img style={{ marginLeft: "10px" }} src="/chat_assets/search.png" />
          </Button>
        ) : (
          <></>
        )}
        <Box
          sx={[
            styles.survey.status,
            styles.right,
            props.survey.status == 1
              ? styles.red
              : props.survey.status == 4
              ? styles.green
              : styles.blue,
          ]}
        >
          <InputLabel
            sx={
              props.survey.status == 1
                ? styles.red
                : props.survey.status == 4
                ? styles.green
                : styles.blue
            }
            marginBottom={0}
          >
            {status}
          </InputLabel>
        </Box>
      </Box>
    </>
  );
};
export default SurveyPatientResult;
