import { useState } from "react";
import { Button, InputLabel, Box } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { TextField } from "../theme/vs-theme";
import withMessages from "./WithMessages";
import { useTranslation } from "react-i18next";

const ProfileData = (props) => {
  const [summary, setSummary] = useState(props.summary ?? "");
  const { user } = useAuth();
  const { t } = useTranslation();
  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "15px",
    },
    title: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
      paddingBottom: "10px",
    },
    labels: {
      whiteSpace: "pre-wrap",
      overflowWrap: "anywhere",
    },
  };
  const changeSummary = (event) => {
    setSummary(event.target.value);
  };

  const saveSummary = () => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/memberships/${props.id}/summary`,
        dotenv.API_URL
      );
      axios
        .put(
          url.href,
          { summary: summary },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then(() => {
          props.showMessage(t("cola_res_success"));
        })
        .catch()
        .finally();
    };
    fetchData();
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex" }}>
        <Box width={"49.5%"}>
          <InputLabel sx={styles.title}>{t("profile_data")}</InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("gender")}:</b>
            {props.profileData?.gender}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("age")}: </b>
            {props.profileData?.age}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("date_birth")}: </b>
            {moment(props.profileData?.birthdate).format("DD/MM/YYYY")}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("email")}: </b>
            {props.profileData?.email}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("identification")}: </b>
            {props.profileData?.id_type + " " + props.profileData?.id_number}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("adress")}: </b>
            {props.profileData?.address}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("phone")}:</b>
            {props.profileData?.phone}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("location")}: </b>
            {props.profileData?.city}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>
              {t("height")[0].toUpperCase() + t("height").slice(1)}: 
            </b>
          </InputLabel>
          {props?.pathology === "HTA" ? (
            <InputLabel sx={styles.labels}>
              <b>{t("weight")}: </b>
              {props?.profileData?.weight?.value}kg
            </InputLabel>
          ) : (
            <></>
          )}
          <InputLabel sx={styles.labels}>
            <b>{t("guardian_name_lastname")}: </b>
            {props.profileData?.guardian_fullname}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("guardian_email")}: </b>
            {props.profileData?.guardian_email}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("guardian_phone")}: </b>
            {props.profileData?.guardian_phone}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("family_name_surname")}: </b>
            {props.profileData?.relative_fullname}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("family_email")}: </b>
            {props.profileData?.relative_email}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("family_phone")}: </b>
            {props.profileData?.relative_phone}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("emergency_name")}: </b>
            {props.profileData?.emergency_fullname}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("emergency_email")}: </b>
            {props.profileData?.emergency_email}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("emergency_phone")}: </b>
            {props.profileData?.emergency_phone}
          </InputLabel>

          <InputLabel sx={styles.labels}>
            <b>{t("name_surname_primary_physician")}: </b>
            {props.profileData?.gp_fullname}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("primary_care_email")}: </b>
            {props.profileData?.gp_email}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("primary_care_phone")}: </b>
            {props.profileData?.gp_phone}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("intitution_name")}: </b>
            {props.profileData?.institution_fullname}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("email_intitution")}: </b>
            {props.profileData?.institution_email}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("phone_intitution")}: </b>
            {props.profileData?.institution_phone}
          </InputLabel>
        </Box>
        <Box width={"49.5%"} ml={"1%"}>
          <InputLabel
            sx={[styles.title, { fontSize: "24px", paddingBottom: "0px" }]}
          >
            {t("collaborative_summary")}
          </InputLabel>
          <TextField
            multiline
            rows={16}
            fullWidth
            value={summary}
            onChange={changeSummary}
          ></TextField>
          <Button
            sx={{ backgroundColor: "#5EC360", color: "#FCFCFC" }}
            onClick={() => saveSummary()}
          >
            {t("save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default withMessages(ProfileData);
