import { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import dotenv from "react-dotenv";
import { TextField } from "../../theme/vs-theme";
import WithMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";

const AdminsCreatePage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    name: "",
    lastname: "",
    institution: null,
    institutionName: "",
    phone: "",
    email: "",
    avatar: null,
    password: "admin",
  });

  const location = useLocation();

  useEffect(() => {
    if (location.state?.name) {
      props.setTitle(
        ["Instituciones", location.state.name, "Administrativos"],
        ""
      );
    } else {
      props.setTitle(
        [
          "Instituciones",
          user.institutions.find((inst) => inst.id === id).name,
        ],
        ""
      );
    }
  }, []);

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const setAvatar = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setForm({ ...form, avatar: file });
    }
  };

  const saveAdmin = () => {
    setIsLoading(true);
    axios
      .post(new URL("/institutions/v1/admins", dotenv.API_URL).href, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then(() =>
        props.showMessage(t("admin_deleted_successfully"), () => navigate(-1))
      )
      .catch((error) => setErrors(error.response.data?.errors))
      .finally(() => {
        setIsLoading(false);
      });
  };

  function randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = new URL(
        `/institutions/v1/institutions/${id}`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setForm((prevState) => ({
            ...prevState,
            institution: id,
            institutionName: response.data.name,
          }));
        })
        .catch((error) => setErrors(error.response.data?.errors))
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      <Grid>
        <Avatar
          alt="avatar"
          src={previewSrc}
          sx={{
            width: "80px",
            height: "80px",
            border: 4,
            backgroundColor: "#D9D9D9",
            borderColor: "white",
          }}
        />
      </Grid>
      <Grid xs={2}>
        <label htmlFor="avatar-file">
          <input
            name="avatar"
            onClick={(event) => {
              event.target.value = null;
            }}
            onInput={setAvatar}
            accept="image/*"
            id="avatar-file"
            type="file"
            style={{ display: "none" }}
          />
          <Button
            variant="contained"
            component="span"
            color="primary"
            endIcon={<img src={"/plus.png"} alt={"Avatar"} />}
            sx={{ width: 200, ml: 3 }}
          >
            {t("add_photo")}
          </Button>
        </label>
      </Grid>
      <Grid xs={12} sx={{ mt: 4 }}>
        <Paper sx={{ backgroundColor: "white", padding: 4 }} elevation={0}>
          <Grid container spacing={4}>
            <Grid xs={3}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="name-input">
                  {t("name")}
                </InputLabel>
                <TextField
                  id="name-input"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="name-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.name && errors.name[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={3}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="lastname-input">
                  {t("lastname")}
                </InputLabel>
                <TextField
                  id="lastname-input"
                  name="lastname"
                  value={form.lastname}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="lastname-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.lastname && errors.lastname[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={2}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="phone-input">
                  {t("phone")}
                </InputLabel>
                <TextField
                  id="phone-input"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="phone-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.phone && errors.phone[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={4}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="email-input">
                  {t("email")}
                </InputLabel>
                <TextField
                  id="email-input"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="email-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.email && errors.email[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" spacing={4}>
            <Grid xs={2}>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate(-1)}
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                color="success"
                onClick={saveAdmin}
                disabled={isLoading}
              >
                {t("enrollment")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Backdrop
        sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default WithMessages(AdminsCreatePage);
