import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../hooks/useAuth";
import dotenv from "react-dotenv";
import axios from "axios";
import ReactECharts from "echarts-for-react";

const LineChartsMeasurement = (props) => {
  const { user } = useAuth();
  const [xLabels, setXLabels] = useState([]);
  const [yValues, setYValues] = useState([]);
  const [y2Values, setY2Values] = useState([]);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  const echartsRef = useRef(null);

  useEffect(() => {
    if (!props.jsonComplete || !props.jsonComplete[0]) {
      if (props.slug) {
        getMeasurements(props.slug);
      }
      if (props.slug2) {
        getMeasurements(props.slug2, true);
      }
    }
  }, [props.count, props.startDate, props.endDate, props.slug, props.slug2]);

  // Si los datos vienen en un json de antes
  useEffect(() => {
    if (props.jsonComplete) {
      if (props.jsonComplete?.length > 1) {
        if (props.jsonComplete[0]) {
          setXLabels(props.jsonComplete[0]?.data.map((item) => item.x));
          setYValues(props.jsonComplete[0]?.data.map((item) => item.y));
          setY2Values(props.jsonComplete[1]?.data.map((item) => item.y));
        }
      } else {
        setXLabels(props.jsonComplete?.data.map((item) => item.x));
        setYValues(props.jsonComplete?.data.map((item) => item.y));
      }
    }
  }, [props.jsonComplete]);

  useEffect(() => {
    if (minValue != null && maxValue != null) {
      const timer = setTimeout(() => {
        props.chartImage(
          echartsRef.current.getEchartsInstance().getDataURL(),
          props.slug
        );
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [maxValue, minValue]);

  useEffect(() => {
    if (props.slug2 && y2Values) {
      let minValue = Math.min(...y2Values) - 10;
      minValue = Math.floor(minValue / 5) * 5;
      setMinValue(minValue);
    }
  }, [y2Values]);

  useEffect(() => {
    if (props.slug != "how-i-feel" && yValues) {
      let maxValue = Math.max(...yValues) + 10;
      maxValue = Math.floor(maxValue / 5) * 5;
      setMaxValue(maxValue);
      if (!props.slug2 && y2Values) {
        let minValue = Math.min(...yValues) - 10;
        minValue = Math.floor(minValue / 5) * 5;
        setMinValue(minValue);
      }
    } else {
      setMinValue(1);
      setMaxValue(3.5);
    }
  }, [yValues]);

  const getMeasurements = (slug, second = false) => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/users/${props.patient}/get-measurements-chart/${slug}`,
        dotenv.API_URL
      );
      url.searchParams.set("count", props?.count);
      if (props.startDate) {
        url.searchParams.set("startDate", props?.startDate);
      }
      if (props.endDate) {
        url.searchParams.set("endDate", props?.endDate);
      }
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (!second) {
            setXLabels(response.data.data.map((item) => item.x));
            setYValues(response.data.data.map((item) => item.y));
          } else {
            setY2Values(response.data.data.map((item) => item.y));
          }
        })
        .finally(() => {});
    };
    fetchData();
  };

  const option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {},
    toolbox: {
      show: true,
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        dataView: { readOnly: false },
        magicType: { type: ["line", "bar"] },
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xLabels,
    },
    yAxis: {
      type: "value",
      inverse: props.slug == "how-i-feel",
      axisLabel: {
        formatter:
          props.slug == "how-i-feel"
            ? function (data) {
                if (data == 1) return "Bien";
                else if (data == 2) {
                  return "Regular";
                } else if (data == 3) {
                  return "Mal";
                } else {
                  return null;
                }
              }
            : "{value}",
      },
      min: minValue ?? 0,
      max: maxValue ?? 150,
    },
    series: [
      {
        name: props?.firstTitle,
        type: props.slug != "how-i-feel" ? "line" : "scatter",
        smooth: true,
        symbolSize: props.slug != "how-i-feel" ? 5 : 30,
        data: yValues,
        symbol: function (data) {
          if (props.slug == "how-i-feel") {
            return `image://${window.location.protocol}//${window.location.host}/face${data}.png`;
          }
        },
        markPoint: {
          data:
            props.slug != "how-i-feel"
              ? [
                  { type: "max", name: "Máx" },
                  { type: "min", name: "Mín" },
                ]
              : null,
        },
        markLine:
          props.slug != "how-i-feel"
            ? {
                data: [{ type: "average", name: "Promedio" }],
              }
            : null,
      },
      {
        name: props?.secondTitle,
        type: "line",
        smooth: true,
        data: y2Values,
        color: "#FAB250",
        markPoint: {
          data: [
            { type: "max", name: "Máx" },
            { type: "min", name: "Mín" },
          ],
        },
        markLine: {
          data: [{ type: "average", name: "Promedio" }],
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactECharts ref={echartsRef} option={option} />
    </div>
  );
};

export default LineChartsMeasurement;
