import {
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { useState, useEffect } from "react";
import axios from "axios";
import dotenv from "react-dotenv";
import { useTranslation } from "react-i18next";
import { usePrevious } from "../hooks/usePrevious";

const SelectPathologyWithModalityAndMedicalGroup = (props) => {
  const { t } = useTranslation();
  const [pathologies, setPathologies] = useState([]);
  const modalities = [
    {
      'id': 1, 'name': 'protocol'
    }, 
    {
      'id': 2, 'name': 'maintenance'
    }
  ];
  const [medicalGroups, setMedicalGroups] = useState([]);
  const { user } = useAuth();
  const prevUser = usePrevious(user);
  const prevEndpoint = usePrevious(props.endpoint);

  useEffect(() => {
    getUserPathologies();
  }, []);
  
  useEffect(() => {
    if (user == prevUser && props.endpoint == prevEndpoint) return;
    if (user) {
      getUserPreferences();
    }
  }, [user, props.endpoint]);

  useEffect(() => {
    const isSelectedMedicalGroupInGroups = medicalGroups.some(
      (group) => group.id == props.selectedMedicalGroup
    );
    if (!isSelectedMedicalGroupInGroups) {
      props.setSelectedMedicalGroup(0);
    }
  }, [medicalGroups]);

  const handlePathologyChange = (event) => {
    props.setSelectedPathology(event.target.value);
    setPreference(props.preferencePathology, event.target.value);
    getMedicalGroups(event.target.value.pathology);
  };

  const handleMedicalGroupChange = (event) => {
    props.setSelectedMedicalGroup(event.target.value);
    setPreference(props.preferenceMedicalGroup, event.target.value);
  };

  const getUserPreferences = () => {
    const fetchData = async () => {
      if (!user.user.id) return;
      const url = new URL(
        `/institutions/v1/users/${user.user.id}/preferences/${props.endpoint}`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data.length != 0) {
            if (response.data[props.preferencePathology]) {
              props.setSelectedPathology(JSON.parse(response.data[props.preferencePathology]))
            } else {
              props.setSelectedPathology({'pathology': 1, 'modality': null});
              const jsonStringValid = `{'pathology': 1, 'modality': null}`.replace(/'/g, '"');
              setPreference(props.preferencePathology, jsonStringValid)
            }
            props.setSelectedMedicalGroup(response.data[props.preferenceMedicalGroup] ?? 0);
            getMedicalGroups(JSON.parse(response.data[props.preferencePathology]).pathology ?? 1);
          }else {
            props.setSelectedPathology(
              {'pathology': 1, 'modality': null}
            );
            props.setSelectedMedicalGroup(0);
            getMedicalGroups(1);
          }
        });
    };
    fetchData();
  };

  const getUserPathologies = () => {
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/users/get-user-pathologies",
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setPathologies(response.data.pathologies);
        });
    };
    fetchData();
  };

  const getMedicalGroups = (pathology_id) => {
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/medical-groups/by-pathology/" + pathology_id,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          let mGroups = [{ id: 0, name: "TODOS" }];
          mGroups.push(response.data["medical-groups"]);
          mGroups = mGroups.flat(1);
          setMedicalGroups(mGroups);
        });
    };
    fetchData();
  };

  const setPreference = (key, value) => {
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/users/set-user-preference",
        dotenv.API_URL
      );
      axios.post(
        url.href,
        { key: key, value: value },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      );
    };
    fetchData();
  };

  const select = {
    backgroundColor: "#FFF",
    borderRadius: "8px",
    border: "1px solid #869AE8",
    color: "#869AE8",
    height: "55px",
    minWidth: "190px",
  };

  return (
    <>
      {pathologies.length > 0 && medicalGroups.length > 0 ? (
        <>
          <Box>
            <InputLabel>{t("filter_by")}: </InputLabel>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box marginLeft={"10px"}>
                <FormHelperText>{t("patology")}</FormHelperText>
                <Select
                  displayEmpty
                  sx={select}
                  id="patology-input"
                  name="selectedPathology"
                  value={props.selectedPathology}
                  onChange={handlePathologyChange}
                  renderValue={(selected) => {
                    let pathology = pathologies.find(path => path?.id == selected?.pathology)
                    if (pathology?.name == "ICC") {
                      return pathology?.name;
                    } else if (pathology?.name == "HTA") {
                      let modality = modalities.find(mod => mod?.id == selected?.modality)
                      return pathology?.name + ' - ' + t(modality?.name);
                    }
                  }}
                >
                  {pathologies.map((pathology) => (
                    pathology.name == 'HTA'? 
                      modalities.map((modality) => (
                        <MenuItem value={{'pathology': pathology.id, 'modality': modality.id}} key={{'pathology': pathology.id, 'modality': modality.id}}>
                          {pathology.name + ' - ' + t(modality.name)}
                        </MenuItem> 
                      ))
                      :
                      <MenuItem value={{'pathology': pathology.id, 'modality': null}} key={{'pathology': pathology.id, 'modality': null}}>
                        {pathology.name}
                      </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box marginLeft={"10px"}>
                <FormHelperText>{t("medicalGroup")}</FormHelperText>
                <Select
                  displayEmpty
                  label="Equipo médico"
                  sx={select}
                  id="medical-group-input"
                  name="selectedMedicalGroup"
                  value={props.selectedMedicalGroup}
                  onChange={handleMedicalGroupChange}
                >
                  {medicalGroups.map((mg) => (
                    <MenuItem value={mg.id} key={mg.id}>
                      {mg.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default SelectPathologyWithModalityAndMedicalGroup;
